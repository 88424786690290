import React from "react";
import EmptyStates from "../../components/sharedComponets/EmptyStates/index.js";
import GetHistogramAction from "../Dashboard/action/GetReviewHistogramAction.js";
import GetHistogramStore from "../Dashboard/store/GetHistogramStore.js";
import { LocaleProvider, DatePicker } from "antd";
import FunnelChartAction from "../Dashboard/action/PieChartAction.js";
import PieChartStore from "../Dashboard/store/PieChartStore.js";
import GetSuggestionsAction from "../Dashboard/action/GetSuggestionAction.js";
import LoadingStatusStore from "../Dashboard/store/LoadingStatusStore.js";
import GetProfilesStore from "../AuthorisePage/store/GetProfilesStore.js";
import ChangeProfileStore from "../Dashboard/store/ChangeProfileStore.js";
import GetDataStatusAction from "../Dashboard/action/GetDataStatusAction.js";
import GetDataStatusStore from "../Dashboard/store/GetDataStatusStore.js";
import GetDataFetchStatusStore from "../Dashboard/store/GetDataFetchStatusStore.js";
import GetReviewsCountAction from "../Dashboard/action/GetReviewsCountAction.js";
import GetReviewsCountStore from "../Dashboard/store/GetReviewsCountStore.js";
import TopLocationsAction from "../Dashboard/action/TopLocationsAction.js";
import TopLocationsStore from "../Dashboard/store/TopLocationsStore.js";
import GetResponsesByRatingAction from "../Dashboard/action/GetRepliesByRatingAction.js";
import GetResponsesByRatingStore from "../Dashboard/store/GetRepliesByRatingStore.js";
import DateWiseReviewHistoAction from "../Dashboard/action/DateWiseReviewHistoAction.js";
import DateWiseHistogramStore from "../Dashboard/store/DateWiseHistogramStore.js";
import CommentsCountAction from "../ReviewsDashboard/action/CommentCountAction.js";
import CommentWordCountAction from "../Dashboard/action/CommentWordCountAction.js";
import CommentWordCountStore from "../Dashboard/store/CommentWordCountStore.js";
import "../../../public/css/print.css";
import "../DownloadPage/style.scss";
import CommentRatioChart from "../../components/CommentRatioChart/index.js";
import CommentWordCountChart from "../../components/CommentWordCountChart/index.js";
import CommentLengthChart from "../../components/CommentLengthChart/index.js";
import moment from "moment";
import {
	METRIC_CARD_CONSTANTS,
	RECENT_CHART_DESC,
	TOOLTIP_CONSTANTS,
} from "../ReviewsDashboard/dashboardPageConstants.js";
import MetricCard from "../../components/MetricCard/index.js";
import styles from "./styles.module.scss";
import ReviewSourcesCard from "../../components/ReviewSourceCard/index.js";
import RatingGraphWrapper from "../../components/RatingGraphWrapper/index.js";
import ReviewReviewChartWrapper from "../../components/ReviewReviewChartWrapper/index.js";
import DashboardTableWrapper from "../../components/DashboardTableWrapper/index.js";
import StackedBarLineComposedChart from "../../components/StackedBarLineComposedChart/index.js";
import { colourLabelMap } from "../../components/ResponseTimeBarChart/Childrens/ColorLabelMap.js";
import ResponseTimeBarChart from "../../components/ResponseTimeBarChart/index.js";
import CommentsGraphWrapper from "../../components/CommentGraphsWrapper/index.js";
import DashboardReviewWaveChartWrapper from "../../components/DashboardReviewWaveChartWrapper/index.js";
import ReviewRatingWaveChart from "../../components/ReviewWaveRatingChart/index.js";
import GetReviewsOverViewAction from "../ReviewsDashboard/action/GetReviewsOverViewAction.js";
import { urlName } from "../../config/index.js";
import GetReviewsOverviewStore from "../ReviewsDashboard/store/GetReviewsOverviewStore.js";
import GetReviewBySourceAction from "../ReviewsDashboard/action/GetReviewBySourceAction.js";
import GetReviewsBySourceStore from "../ReviewsDashboard/store/GetReviewsBySourceStore.js";
import GetReviewByRatingStore from "../ReviewsDashboard/store/GetReviewByRatingStore.js";
import GetReviewByRatingAction from "../ReviewsDashboard/action/GetReviewByRatingAction.js";
import GetResponseByRatingStore from "../ReviewsDashboard/store/GetResponseByRatingStore.js";
import GetResponseByRatingAction from "../ReviewsDashboard/action/GetResponseByRatingAction.js";
import GetRecentReviewsAction from "../ReviewsDashboard/action/GetRecentReviewsAction.js";
import GetRecentReviewsStore from "../ReviewsDashboard/store/GetRecentreviewsStore.js";
import GetResponseHistogramAction from "../ReviewsDashboard/action/GetHistogramAction.js";
import GetResponseHistogramStore from "../ReviewsDashboard/store/GetHistogramStore.js";
import GetReviewResponseHistogramStore from "../ReviewsDashboard/store/GetReviewResponseHistogramStore.js";
import GetReviewResponseHistogramAction from "../ReviewsDashboard/action/GetReviewResponseHistogramAction.js";
import CommentsCountStore from "../ReviewsDashboard/store/CommentsCountStore.js";
import GetCommentWordCountStore from "../ReviewsDashboard/store/GetCommentWordCountStore.js";
import GetCommentWordCountAction from "../ReviewsDashboard/action/GetCommentWordCountAction.js";
import ReviewRatingWaveChartStore from "../ReviewsDashboard/store/ReviewRatingWaveChartStore.js";
import { colorSpectrumGenerator } from "../../utils/colorSpectrumGenerator.js";
import { colorSpectrum } from "../CompetitiveSection/childrens/CompetitorConstants.js";
import ReviewRatingWaveChartAction from "../ReviewsDashboard/action/ReviewRatingWaveChartAction.js";
import cookie from "../../utils/cookie.js";

var config = require("../../../globalconfig.js");
var _ = require("lodash");
let colourMap = _.cloneDeep(colourLabelMap);

var intervalFunction;
var languageFile = require("../../../public/languages/Translation.js");

const fieldsMap = {
	profileName: "Profile",
	country: "Country",
	area: "State",
	locality: "Administrative area",
	granularity: "Granularity",
	fromDate: "from",
	toDate: "to",
	starType: "Star rating",
	starValue: "",
	organizationName: "Account name",
	gt: "Greater than",
	lt: "Less than",
	equals: "Equals",
	GT: "Greater than",
	LT: "Less than",
	EQUALS: "Equals",
	reviewCategory: "Review Category",
	locationFilterName: "Group",
	jobType: "Report",
};

class DashboardReviewPdf extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			pieChartLoading: true,
			publishedLocations: 0,
			verifiedLocations: 0,
			openInfo: {},
			queriesPiedata: {},
			actionsPieData: {},
			viewsPiedata: {},
			viewsLoading: false,
			queriesLoading: false,
			actionsLoading: false,
			metricLoader: false,
			metricData: [],
			commentWordCountData: [],
			starRatingsData: [],
			reviewSourceData: [],
			responseByRating: [],
			recentReviewData: [],
			commentsCountData: [],
			nodeColorMap: [],
			waveCharatLocationData: [],
			reviewResponseHistogramTimeData: [],
			reviewResponseHistogramData: [],
			dateInterval: 0,
			searchesCount: 0,
			viewsCount: 0,
			currentGranularity: { value: "weekly", label: "Weekly" },
			actionsCount: 0,
			dateRange: {
				fromDate: moment().subtract(16, "week").format("YYYY-MM-DD"),
				toDate: moment().format("YYYY-MM-DD"),
			},
			repliesByRatingLoading: true,
		};
		this.parseUrl = this.parseUrl.bind(this);
		this.onReviewsCountResponse = this.onReviewsCountResponse.bind(this);
		this.topLocationsResponse = this.topLocationsResponse.bind(this);
		this.onDateWiseResponse = this.onDateWiseResponse.bind(this);
		this.onFetchStatus = this.onFetchStatus.bind(this);
		this.onStatusResponse = this.onStatusResponse.bind(this);
		this.onProfileChange = this.onProfileChange.bind(this);
		this.onLoadChange = this.onLoadChange.bind(this);
		this.onRepliesByStarRating = this.onRepliesByStarRating.bind(this);
		this.pieChartResponse = this.pieChartResponse.bind(this);
		this.onGraphResponse = this.onGraphResponse.bind(this);
		this.generateReportFilters = this.generateReportFilters.bind(this);
		this.generateReportFilters = this.generateReportFilters.bind(this);
		this.generateReportHeader = this.generateReportHeader.bind(this);
		this.getData = this.getData.bind(this);
		this.ChangeFormateDate = this.ChangeFormateDate.bind(this);
		this.ChangeDivider = this.ChangeDivider.bind(this);
	}
	componentWillMount() {
		GetDataStatusStore.bind(this.onStatusResponse);
		GetHistogramStore.bind(this.onGraphResponse);
		PieChartStore.bind(this.pieChartResponse);
		GetResponsesByRatingStore.bind(this.onRepliesByStarRating);
		// GetLocationsStatesStore.bind(this.onLocationStates);
		// GetLocationInsightStore.bind(this.onInsightsResponse);
		// ActionsTypeCountStore.bind(this.onActionsCountResponse);
		// ViewsTypeCountStore.bind(this.onViewsCountResponse);
		// PhotosTypeCountStore.bind(this.onPhotosCountResponse);
		// PhotosTypeCountCustomerStore.bind(this.onPhotosCountResponseCustomer);
		// QueriesTypeCountStore.bind(this.onQueriesCountResponse);
		LoadingStatusStore.bind(this.onLoadChange);
		GetProfilesStore.bind(this.onProfilesResponse);
		ChangeProfileStore.bind(this.onProfileChange);
		GetDataFetchStatusStore.bind(this.onFetchStatus);
		GetReviewsCountStore.bind(this.onReviewsCountResponse);
		TopLocationsStore.bind(this.topLocationsResponse);
		// LocalHistogramStore.bind(this.LocalHistogram);
		DateWiseHistogramStore.bind(this.onDateWiseResponse);
		// CommentsCountStore.bind(this.onWithAndWithoutCommentsCount);
		CommentsCountStore.bind(this.getCommentsCount);
		CommentWordCountStore.bind(this.onCommentLengthResponse);
		GetReviewsOverviewStore.bind(this.getReviewsOverViewResponse);
		GetReviewsBySourceStore.bind(this.getReviewBySourceResponse);
		GetReviewByRatingStore.bind(this.getReviewByResponse);
		GetResponseByRatingStore.bind(this.getResponseByRating);
		GetRecentReviewsStore.bind(this.getRecentReviewResponse);
		GetResponseHistogramStore.bind(this.getHistogramResponse);
		GetReviewResponseHistogramStore.bind(this.getReviewResponseHisto);
		GetCommentWordCountStore.bind(this.getCommentResponse);
		ReviewRatingWaveChartStore.bind(this.getReviewRatingWaveResponse);

		// DatewiseInsightHistoStore.bind(this.onDatewiseActionCount);
		this.jobType = null;
	}
	parseUrl(queryParams) {
		// console.log(" query is ", queryParams)
		var filtersObject = {};
		var fields = queryParams.split('&');
		// console.log("query2", fields)
		for (var i in fields) {
			if (fields[i].split("=")[1]) {
				if (fields[i].split("=")[0] == "jobType") {
					this.jobType = decodeURIComponent(fields[i].split("=")[1]);
				}
				if (
					fields[i].split("=")[0] == "fromDate" ||
					fields[i].split("=")[0] == "toDate"
				) {
					if (!filtersObject.hasOwnProperty("reviewFilters")) {
						filtersObject.reviewFilters = {};
						filtersObject.reviewFilters.dateRange = {};
					}
					if (fields[i].split("=")[0] == "fromDate") {
						filtersObject.reviewFilters.dateRange.from = decodeURIComponent(
							fields[i].split("=")[1],
						);
					}
					if (fields[i].split("=")[0] == "toDate") {
						filtersObject.reviewFilters.dateRange.to = decodeURIComponent(
							fields[i].split("=")[1],
						);
					}
				}
				if (
					fields[i].split("=")[0] == "starType" ||
					fields[i].split("=")[0] == "starValue"
				) {
					if (!filtersObject.hasOwnProperty("reviewFilters")) {
						filtersObject.reviewFilters = {};
					} else if (
						!filtersObject.reviewFilters.hasOwnProperty("starRatingFilter")
					) {
						filtersObject.reviewFilters.starRatingFilter = {};
					}
					if (fields[i].split("=")[0] == "starType") {
						filtersObject.reviewFilters.starRatingFilter["type"] =
							decodeURIComponent(fields[i].split("=")[1]);
					}
					if (fields[i].split("=")[0] == "starValue") {
						filtersObject.reviewFilters.starRatingFilter["value"] =
							decodeURIComponent(fields[i].split("=")[1]);
					}
				}
				//adding reviewCategory to the request body
				//by Liju Kuriakose
				if (fields[i].split("=")[0] == "reviewCategory") {
					if (!filtersObject.hasOwnProperty("reviewFilters")) {
						filtersObject.reviewFilters = {};
					}
					if (fields[i].split("=")[0] == "reviewCategory") {
						filtersObject.reviewFilters.reviewCategory = decodeURIComponent(
							fields[i].split("=")[1],
						);
					}
				}
				if (fields[i].split("=")[0] == "locationFilterId") {
					filtersObject["locationFilterId"] = decodeURIComponent(
						fields[i].split("=")[1],
					);
				}
				//
				if (
					fields[i].split("=")[0] == "country" ||
					fields[i].split("=")[0] == "area" ||
					fields[i].split("=")[0] == "locality" ||
					fields[i].split("=")[0] == "locationName"
				) {
					if (!filtersObject.hasOwnProperty("locationFilters")) {
						filtersObject.locationFilters = {};
					}
				}
				if (fields[i].split("=")[0] == "country") {
					filtersObject.locationFilters.country = decodeURIComponent(
						fields[i].split("=")[1],
					);
				}
				if (fields[i].split("=")[0] == "area") {
					filtersObject.locationFilters.area = decodeURIComponent(
						fields[i].split("=")[1],
					);
				}
				if (fields[i].split("=")[0] == "locality") {
					filtersObject.locationFilters.locality = decodeURIComponent(
						fields[i].split("=")[1],
					);
				}
				if (fields[i].split("=")[0] == "locationName") {
					filtersObject.locationFilters.locationName = decodeURIComponent(
						fields[i].split("=")[1],
					);
				}
				if (fields[i].split("=")[0] == "searchText") {
					filtersObject.reviewFilters.searchText = decodeURIComponent(
						fields[i].split("=")[1],
					);
				}
				filtersObject[fields[i].split("=")[0]] = decodeURIComponent(
					fields[i].split("=")[1],
				);
				//by Liju Kuriakose
				if (fields[i].split("=")[0] == "organizations") {
					filtersObject.organizations = [
						decodeURIComponent(fields[i].split("=")[1]),
					];
				}
				if (fields[i].split("=")[0] == "sources") {
					let sorceList = decodeURIComponent(fields[i].split("=")[1]);
					filtersObject.sources = sorceList.split(",").map((item) => {
						return item.replace("[", "").replace("]", "");
					});
				}
			}
		}
		filtersObject.dateRange = {
			from: filtersObject.fromDate,
			to: filtersObject.toDate,
			
		};
		
		this.setState({
			dateInterval: moment(filtersObject.dateRange.to).diff(
				moment(filtersObject.dateRange.from),
				"days",
			),
		});
		cookie.createCookie('lctoken', filtersObject.token, 2);
		localStorage.setItem('profileId', filtersObject.profileId);
		localStorage.setItem(
			'masterLocationFilter',
			filtersObject.masterLocationFilter
		);
		localStorage.setItem('filters', filtersObject);
		delete filtersObject['token'];
		if (filtersObject.language == 'portuguese') {
			moment.locale('pt');
		} else if (filtersObject.language == 'english') {
			moment.locale('en');
		} else if (filtersObject.language == 'german') {
			moment.locale('de');
		} else if (filtersObject.language == 'spanish') {
			moment.locale('es');
		} else if (filtersObject.language == 'french') {
			moment.locale('fr');
		} else if (filtersObject.language == '日本語') {
			moment.locale('ja');
		}
		return filtersObject;
	}
	componentDidMount() {
		//GetOrganisationsAction.getOrgs();

		var filters = this.parseUrl(this.props.match.params.query);
		// console.log("filters are here....", filters)

		// StackedAreaChartAction.addDownloadFilters(filters);
		// InsightGroupBarchartAction.addDownloadFilters(filters);
		// LineChartAction.addDownloadFilters(filters);
		// LocalHistogramAction.addDownloadFilters(filters);
		// ActionsTypeCountAction.addDownloadFilters(filters);
		// QueriesTypeCountAction.addDownloadFilters(filters);
		// ViewsTypeCountAction.addDownloadFilters(filters);
		// PhotosTypeCountAction.addDownloadFilters(filters);
		FunnelChartAction.addDownloadFilters(filters);
		GetResponsesByRatingAction.addDownloadFilters(filters);
		// GetLocationsStatesAction.addDownloadFilters(filters);
		// StackedBarChartAction.addDownloadFilters(filters);
		GetReviewsCountAction.addDownloadFilters(filters);
		GetHistogramAction.addDownloadFilters(filters);
		DateWiseReviewHistoAction.addDownloadFilters(filters);
		// CommentsCountAction.addDownloadFilters(filters);
		CommentWordCountAction.addDownloadFilters(filters);
		// DatewiseInsightHistoAction.addDownloadFilters(filters);
		// GetLocationInsightAction.addDownloadFilters(filters);
		TopLocationsAction.addDownloadFilters(filters);
		GetReviewsOverViewAction.getReviews({
			profileId: localStorage.getItem("profileId"),
			masterLocationFilter: localStorage.getItem("masterLocationFilter"),
			domainName: urlName,
			// filters: {
			// 	dateRange: props.dateRange,
			// },
			filters: filters,
		});
		GetReviewBySourceAction.getReviewSource({
			profileId: localStorage.getItem("profileId"),
			sources: [],
			filters,
			domainName: urlName,
		});
		GetReviewByRatingAction.getRating({
			profileId: localStorage.getItem("profileId"),
			sources: [],
			filters: filters,
			domainName: urlName,
		});
		GetResponseByRatingAction.getRating({
			profileId: localStorage.getItem("profileId"),
			sources: [],
			filters: filters,
			domainName: urlName,
		});
		GetRecentReviewsAction.getRecentReviews({
			profileId: localStorage.getItem("profileId"),
			masterLocationFilter: localStorage.getItem("masterLocationFilter"),
			domainName: urlName,
			filters: filters,
			size: 500,
		});
		GetResponseHistogramAction.getData({
			granularity: "daily",
			sources: [],
			filters,
			domainName: urlName,
			profileId: localStorage.getItem("profileId"),
		});
		GetReviewResponseHistogramAction.getReviewResponseHisto({
			profileId: localStorage.getItem("profileId"),
			filters,
		});

		CommentsCountAction.getCount({
			profileId: localStorage.getItem("profileId"),
			filters,
			sources: [],
			granularity: "daily",
		});
		GetCommentWordCountAction.getWordCount({
			profileId: localStorage.getItem("profileId"),
			filters,
			sources: [],
			granularity: "daily",
		});

		ReviewRatingWaveChartAction.getData({
			profileId: localStorage.getItem("profileId"),
			profileName: localStorage.getItem("profileName"),
			filters,
		});
	}
	componentWillUnmount() {
		GetDataStatusStore.unbind(this.onStatusResponse);
		GetHistogramStore.unbind(this.onGraphResponse);
		PieChartStore.unbind(this.pieChartResponse);
		GetResponsesByRatingStore.unbind(this.onRepliesByStarRating);
		// GetLocationsStatesStore.unbind(this.onLocationStates);
		// GetLocationInsightStore.unbind(this.onInsightsResponse);
		// ActionsTypeCountStore.unbind(this.onActionsCountResponse);
		// ViewsTypeCountStore.unbind(this.onViewsCountResponse);
		// QueriesTypeCountStore.unbind(this.onQueriesCountResponse);
		LoadingStatusStore.unbind(this.onLoadChange);
		// PhotosTypeCountStore.unbind(this.onPhotosCountResponse);
		// PhotosTypeCountCustomerStore.unbind(this.onPhotosCountResponseCustomer);
		GetProfilesStore.unbind(this.onProfilesResponse);
		ChangeProfileStore.unbind(this.onProfileChange);
		GetHistogramAction.clearFilters();
		DateWiseReviewHistoAction.clearFilters();
		CommentsCountAction.clearFilters();
		CommentWordCountAction.clearFilters();
		// DatewiseInsightHistoAction.clearFilters();
		GetDataFetchStatusStore.unbind(this.onFetchStatus);
		GetReviewsCountStore.unbind(this.onReviewsCountResponse);
		TopLocationsStore.unbind(this.topLocationsResponse);
		// LocalHistogramStore.unbind(this.LocalHistogram);
		DateWiseHistogramStore.unbind(this.onDateWiseResponse);
		// CommentsCountStore.unbind(this.onWithAndWithoutCommentsCount);
		CommentWordCountStore.unbind(this.onCommentLengthResponse);
		// DatewiseInsightHistoStore.unbind(this.onDatewiseActionCount);
		// InsightGroupBarchartAction.clearFilters();
		GetReviewsOverviewStore.unbind(this.getReviewsOverViewResponse);
		GetReviewsBySourceStore.unbind(this.getReviewBySourceResponse);
		GetReviewByRatingStore.unbind(this.getReviewByResponse);
		GetResponseByRatingStore.unbind(this.getResponseByRating);
		GetRecentReviewsStore.unbind(this.getRecentReviewResponse);
		GetResponseHistogramStore.unbind(this.getHistogramResponse);
		GetReviewResponseHistogramStore.unbind(this.getReviewResponseHisto);
		CommentsCountStore.unbind(this.getCommentsCount);
		GetCommentWordCountStore.unbind(this.getCommentResponse);
		ReviewRatingWaveChartStore.unbind(this.getReviewRatingWaveResponse);

		clearInterval(intervalFunction);
	}
	onWithAndWithoutCommentsCount = () => {
		this.setState({
			commentRatioData: CommentsCountStore.getResponse(),
		});
	};
	onCommentLengthResponse = () => {
		this.setState({
			commentLengthData: CommentWordCountStore.getResponse(),
		});
	};
	getReviewsOverViewResponse = () => {
		let response = GetReviewsOverviewStore.getResponse();
		this.setState({
			metricLoader: false,
			metricData: response,
		});
	};

	getReviewBySourceResponse = () => {
		let response = GetReviewsBySourceStore.getResponse();
		if (response.status == 200) {
			this.setState({
				reviewSourceData:
					(response.sourceWiseRating &&
						response.sourceWiseRating.slice(0, 5)) ||
					[],
			});
		} else {
			this.setState({ reviewSourceData: [] });
		}
	};
	getReviewRatingWaveResponse = () => {
		let response = ReviewRatingWaveChartStore.getResponse();
		if (response.status == 200) {
			let colorDetails = {};
			let extraColours = colorSpectrumGenerator(
				"rgb(176, 224, 230)",
				"rgb(57, 96, 142)",
				response.competitorData.length - 9,
			);
			_.map(response.competitorData, (item, key) => {
				if (key < 10) {
					colorDetails[item.brandName] = colorSpectrum[key + 1];
				} else {
					colorDetails[item.brandName] = `rgb(${extraColours[key - 9]})`;
				}
			});
			this.setState({
				nodeColorMap: colorDetails,
				waveCharatLocationData: response.profileLocationData.concat(
					response.competitorLocationData,
				),
			});
		} else {
			this.setState({ nodeColorMap: [], waveCharatLocationData: [] });
		}
	};
	getCommentResponse = () => {
		let response = GetCommentWordCountStore.getResponse();
		if (response.status == 200) {
			this.setState({ commentWordCountData: response });
		} else {
			this.setState({ commentWordCountData: [] });
		}
	};
	getCommentsCount = () => {
		let response = CommentsCountStore.getResponse();
		if (response.status == 200) {
			this.setState({ commentsCountData: response });
		} else {
			this.setState({ commentsCountData: [] });
		}
	};
	getReviewResponseHisto = () => {
		let response = GetReviewResponseHistogramStore.getResponse();
		if (response.status == 200) {
			let reviewResponse = [];
			if (response.histogram) {
				reviewResponse = response.histogram
					.sort((a, b) => {
						if (a.review < b.review) {
							return -1;
						}
						if (a.review > b.review) {
							return 1;
						}
						return 0;
					})
					.map((item) => {
						let modified = {};
						Object.keys(item).forEach((key) => {
							if (item[key]) {
								if (isNaN(item[key])) {
									modified[key] = item[key];
								} else {
									modified[key] = item[key].toFixed(1) + "%";
								}
								if (colourMap[key]) colourMap[key].shouldEnableLegend = true;
							}
						});
						return modified;
					});
			}
			this.setState({
				reviewResponseHistogramTimeData: reviewResponse,
				reviewResponseHistogramData: response.histogram
					? response.histogram
					: [],
			});
		} else {
			this.setState({
				reviewResponseHistogramTimeData: [],
				reviewResponseHistogramData: [],
			});
		}
	};
	getHistogramResponse = () => {
		let response = GetResponseHistogramStore.getResponse();
		if (response.status == 200) {
			this.setState({ reviewAverageData: response.histogram });
		} else {
			this.setState({ reviewAverageData: [] });
		}
	};
	getRecentReviewResponse = () => {
		let response = GetRecentReviewsStore.getResponse();
		if (response.status == 200) {
			this.setState({
				recentReviewData: response.result,
				recentCount: response.total,
			});
		} else {
			this.setState({
				recentReviewData: [],
				recentCount: 0,
			});
		}
	};
	getResponseByRating = () => {
		let response = GetResponseByRatingStore.getResponse();
		if (response.status == 200) {
			this.setState({ responseByRating: response.replyStarRatings });
		} else {
			this.setState({ responseByRating: [] });
		}
	};
	getReviewByResponse = () => {
		let response = GetReviewByRatingStore.getResponse();
		if (response.status == 200) {
			this.setState({ starRatingsData: response.starRatings });
		} else {
			this.setState({ starRatingsData: [] });
		}
	};
	onReviewsCountResponse() {
		// console.log("count resp", GetReviewsCountStore.getResponse());
		let reviewsAggregateData = {};
		if (!_.isEmpty(GetReviewsCountStore.getResponse())) {
			for (var item in GetReviewsCountStore.getResponse()) {
				if (item !== "totalReviews" && item !== "avgReviews") {
					reviewsAggregateData[item] = GetReviewsCountStore.getResponse()[item];
				}
			}
		}
		this.setState({
			totalReviewsCount: GetReviewsCountStore.getResponse().totalReviews,
			avgReviewsCount:
				Math.round(GetReviewsCountStore.getResponse().avgReviews * 100) / 100,
			reviewsAggregate: reviewsAggregateData,
		});
	}
	topLocationsResponse() {
		// console.log("top locations data", TopLocationsStore.getResponse())
		this.setState({
			topCountries: TopLocationsStore.getResponse().topCountries,
			topAreas: TopLocationsStore.getResponse().topAreas,
			topLocalities: TopLocationsStore.getResponse().topLocalities,
		});
	}
	// LocalHistogram () {
	// 	var localhistogram = LocalHistogramStore.getResponse();
	// 	this.setState({
	// 		LocalHistoStackLabel: localhistogram.metricCount,
	// 	})
	// }
	onDateWiseResponse() {
		var weekdaysMap = {
			Sun: languageFile[localStorage.getItem("language")]["20071"],
			Mon: languageFile[localStorage.getItem("language")]["20011"],
			Tue: languageFile[localStorage.getItem("language")]["20021"],
			Wed: languageFile[localStorage.getItem("language")]["20031"],
			Thu: languageFile[localStorage.getItem("language")]["20041"],
			Fri: languageFile[localStorage.getItem("language")]["20051"],
			Sat: languageFile[localStorage.getItem("language")]["20061"],
		};
		var monthNameMap = {
			Jan: languageFile[localStorage.getItem("language")]["20111"],
			Feb: languageFile[localStorage.getItem("language")]["20121"],
			Mar: languageFile[localStorage.getItem("language")]["20131"],
			Apr: languageFile[localStorage.getItem("language")]["20141"],
			May: languageFile[localStorage.getItem("language")]["20151"],
			Jun: languageFile[localStorage.getItem("language")]["20161"],
			Jul: languageFile[localStorage.getItem("language")]["20171"],
			Aug: languageFile[localStorage.getItem("language")]["20181"],
			Sep: languageFile[localStorage.getItem("language")]["20191"],
			Oct: languageFile[localStorage.getItem("language")]["20201"],
			Nov: languageFile[localStorage.getItem("language")]["20211"],
			Dec: languageFile[localStorage.getItem("language")]["20221"],
		};
		var weekdata =
			DateWiseHistogramStore.getResponse().histogram &&
			DateWiseHistogramStore.getResponse()
				.histogram.dayOfWeekHistogram.sort((a, b) => a.value - b.value)
				.map(function (item, index) {
					var weekdayList = {};
					for (var i in item) {
						var value = item[i];
						if (i === "dayOfWeek") {
							value = weekdaysMap[value];
						}
						weekdayList[i] = value;
					}
					return weekdayList;
				});
		var yeardata =
			DateWiseHistogramStore.getResponse().histogram &&
			DateWiseHistogramStore.getResponse()
				.histogram.monthOfYearHistogram.sort((a, b) => a.value - b.value)
				.map(function (item, index) {
					var yearList = {};
					for (var i in item) {
						var value = item[i];
						if (i === "monthOfYear") {
							value = monthNameMap[value];
						}
						yearList[i] = value;
					}
					return yearList;
				});
		this.setState({
			monthHisto:
				DateWiseHistogramStore.getResponse().histogram &&
				DateWiseHistogramStore.getResponse().histogram.dayOfMonthHistogram.sort(
					(a, b) => a.dayOfMonth - b.dayOfMonth,
				),
			weekHisto: weekdata,
			yearHisto: yeardata,
		});
	}
	// onDatewiseActionCount () {
	// 	var hashMap = { 'ACTIONS_DRIVING_DIRECTIONS': 'Driving Directions', 'ACTIONS_PHONE': 'Phone', 'ACTIONS_WEBSITE': 'Website', 'dayOfWeek': 'dayOfWeek', "value": "value" };
	// 	if (DatewiseInsightHistoStore.getResponse().histogram) {
	// 		var data = DatewiseInsightHistoStore.getResponse().histogram.dayOfWeekHistogram.sort((a, b) => a.value - b.value).map(function (item, index) {
	// 			var singleItem = {};
	// 			for (var i in item) {
	// 				var value = item[i];
	// 				singleItem[hashMap[i]] = value;
	// 			}
	// 			return singleItem;
	// 		})
	// 		this.setState({
	// 			DatewiseInsightData: data,
	// 		})
	// 	}
	// }
	onFetchStatus() {
		// console.log("GetDataFetchStatusStore", GetDataFetchStatusStore.getResponse())
		var statusData = GetDataFetchStatusStore.getResponse();
		var profileFetching = false;
		for (var account in statusData) {
			if (
				statusData[account].status !== "COMPLETED" &&
				statusData.status !== 404
			) {
				profileFetching = true;
			}
		}
		if (profileFetching) {
			var fetchingStatusData = [];
			if (
				GetDataFetchStatusStore.getResponse()[0].status === "FETCHING_LOCATIONS"
			) {
				fetchingStatusData = GetDataFetchStatusStore.getResponse()[0];
				this.setState({
					showInitalizingFetchers: false,
					fetchingStatusData: fetchingStatusData,
					showFetchingLocations: true,
					showFetchingStatus: false,
					loading: false,
				});
			} else if (
				GetDataFetchStatusStore.getResponse()[0].status === "FETCHING"
			) {
				fetchingStatusData = GetDataFetchStatusStore.getResponse()[0];
				this.setState({
					showInitalizingFetchers: true,
					fetchingStatusData: fetchingStatusData,
					showFetchingLocations: true,
					showFetchingStatus: false,
					loading: false,
				});
			} else {
				fetchingStatusData = GetDataFetchStatusStore.getResponse().map(
					function (item, index) {
						return {
							accountName: item.accountName,
							status: item.status,
							percentageFetched: Math.round(
								this.findFetchStatus(
									item.locationsProcessed,
									item.locationsFetched,
								),
							),
						};
					},
					this,
				);
				// console.log("fetchingStatusData", fetchingStatusData)
				this.setState({
					showInitalizingFetchers: false,
					fetchingStatusData: fetchingStatusData,
					dataRetreivalStatus: "FETCHING_REVIEWS_INSIGHTS",
					showFetchingLocations: false,
					showFetchingStatus: true,
				});
			}
			// GetDataFetchStatusAction.getStatus( localStorage.getItem('profileId') );
		} else {
			this.setState({
				showFetchingStatus: false,
			});
		}
	}
	findFetchStatus(locationsProcessed, totalLocations) {
		return (locationsProcessed / totalLocations) * 100;
	}
	onStatusResponse() {
		// console.log("data status resp", GetDataStatusStore.getResponse())
		var statusData = GetDataStatusStore.getResponse().accountFetchStatuses;
		if (
			GetDataStatusStore.getResponse().status === "NO_ACCOUNTS_AUTHORIZED" &&
			GetDataStatusStore.getResponse().status === "NO_ACCOUNTS_ACTIVATED"
		) {
			this.setState({
				dataRetreivalStatus: "NO_ACCOUNTS_AUTHORIZED",
				loading: false,
			});
		} else if (GetDataStatusStore.getResponse().dataAvailability === false) {
			var profileLocationFetching;
			for (var account in statusData) {
				if (statusData[account].status === "NO_ACCOUNTS_AUTHORIZED") {
					this.setState({
						dataRetreivalStatus: "NO_ACCOUNTS_AUTHORIZED",
						loading: false,
					});
					break;
				} else if (statusData[account].status === "NO_ACCOUNTS_ACTIVATED") {
					this.setState({
						dataRetreivalStatus: "NO_ACCOUNTS_ACTIVATED",
						loading: false,
					});
					break;
				}
				if (statusData[account].status === "FETCHING_LOCATIONS") {
					profileLocationFetching = "LocationFetching";
					break;
				} else if (statusData[account].status === "FETCHING") {
					profileLocationFetching = "Fetching";
				} else if (statusData[account] === "QUEUE") {
					profileLocationFetching = "Queue";
				}
			}
			if (profileLocationFetching === "LocationFetching") {
				// GetDataFetchStatusAction.getStatus( localStorage.getItem('profileId') );
				this.setState({
					dataRetreivalStatus: "FETCHING_LOCATIONS_NO_DATA",
					loading: false,
				});
			} else if (profileLocationFetching === "Fetching") {
				this.setState({
					dataRetreivalStatus: "FETCHING_NO_DATA",
					loading: false,
				});
			} else {
				this.setState({
					dataRetreivalStatus: "QUEUE",
					loading: false,
				});
			}
		} else {
			var accountsFetching = false;
			if (
				GetDataStatusStore.getResponse().status === "NO_ACCOUNTS_AUTHORIZED" ||
				GetDataStatusStore.getResponse().status === "NO_ACCOUNTS_ACTIVATED"
			) {
				this.props.history.push("/settings/integrations");
			}
			for (var account in statusData) {
				if (statusData[account].status !== "COMPLETED") {
					accountsFetching = true;
				}
			}
			if (accountsFetching) {
				// GetDataFetchStatusAction.getStatus( localStorage.getItem('profileId') );
			} else {
				this.setState({
					showFetchingStatus: false,
				});
			}
			// console.log("account status", statusData);

			var profileId = localStorage.getItem("profileId");
			GetHistogramAction.changeProfile(profileId);
			DateWiseReviewHistoAction.changeProfile(profileId);
			CommentsCountAction.changeProfile(profileId);
			CommentWordCountAction.changeProfile(profileId);
			// DatewiseInsightHistoAction.changeProfile(profileId);
			FunnelChartAction.changeProfile(profileId);
			GetResponsesByRatingAction.changeProfile(profileId);
			GetSuggestionsAction.getSuggestions({}, profileId);
			// GetLocationsStatesAction.changeProfile(profileId);
			// GetLocationInsightAction.changeProfile(profileId);
			// StackedBarChartAction.changeProfile(profileId);
			// StackedAreaChartAction.changeProfile(profileId);
			// LineChartAction.changeProfile(profileId);
			// LocalHistogramAction.changeProfile(profileId);
			// InsightGroupBarchartAction.loadData();
			// InsightGroupBarchartAction.loadDataCustomer();
			// ActionsTypeCountAction.changeProfile(profileId);
			// ViewsTypeCountAction.changeProfile(profileId);
			// QueriesTypeCountAction.changeProfile(profileId);
			GetReviewsCountAction.changeProfile(profileId);
			this.setState({
				dataRetreivalStatus: GetDataStatusStore.getResponse().status,
			});
		}
	}
	onProfileChange() {
		this.setState({
			loading: true,
		});
		GetDataStatusAction.getStatus(localStorage.getItem("profileId"));
	}
	onProfilesResponse() {
		intervalFunction = setInterval(function () {
			var callDataStatus = false;
			for (var i in GetDataStatusStore.getResponse().accountFetchStatuses) {
				if (
					GetDataStatusStore.getResponse().accountFetchStatuses[i].status !==
					"COMPLETED"
				) {
					callDataStatus = true;
					break;
				}
			}
			if (callDataStatus) {
				GetDataStatusAction.getStatus(localStorage.getItem("profileId"));
			}
		}, 15000);
		GetDataStatusAction.getStatus(localStorage.getItem("profileId"));
		// console.log("pros store", GetProfilesStore.getResponse());
	}
	onLoadChange() {
		this.setState({
			graphLoading: true,
		});
	}
	modifyPieChartData(data, hashMap) {
		var pieChartData = [];
		for (var key in data) {
			pieChartData.push({ name: hashMap[key], value: data[key] });
		}
		return pieChartData;
	}
	onRepliesByStarRating() {
		let repliesByRating = [];
		GetResponsesByRatingStore.getResponse().starRatings.forEach(function (
			item,
		) {
			item.name = item.starRating + " Stars";
			item.value = item.count;
			repliesByRating.push(item);
		});
		this.setState({
			repliesByRating: repliesByRating,
			repliesByRatingLoading: false,
		});
	}
	pieChartResponse() {
		var pieData = [];
		PieChartStore.getResponse().starRatings.forEach(function (item) {
			item.name = item.starRating + " Stars";
			item.value = item.count;
			pieData.push(item);
		});
		this.setState({
			pieData: pieData,
			pieChartLoading: false,
			happinessRating: Math.round(PieChartStore.getResponse().happinessRate),
		});
	}
	dateFormatter(data) {
		var modifiedData = data.map(function (item) {
			for (var i in item) {
				if (i === "date") {
					item[i] = moment(item[i]).format("ll");
				} else {
					if (item[i] === 0) {
						item[i] = null;
					}
				}
			}
		});
		return modifiedData;
	}
	onGraphResponse() {
		if (GetHistogramStore.getResponse().histogram) {
			var data = this.dateFormatter(GetHistogramStore.getResponse().histogram);
		}
		let avg = GetHistogramStore.getResponse().totalAverage
			? GetHistogramStore.getResponse().totalAverage.toFixed(2)
			: "-";
		this.setState({
			loading: false,
			data: GetHistogramStore.getResponse().histogram,
			avg: avg,
			totalCount: GetHistogramStore.getResponse().totalCount,
		});
	}
	findPercent(label, data) {
		if (data) {
			if (data.length === 0) {
				return 0;
			} else {
				var sum = 0;
				for (var i in data) {
					sum = sum + data[i].value;
				}
				var percent;
				for (var i in data) {
					if (data[i].name === label) {
						percent = (data[i].value / sum) * 100;
					}
				}
				return Math.round(percent * 100) / 100 + "%";
			}
		}
	}
	findNumShortForm(label, data) {
		if (data) {
			var value = _.find(data, function (item) {
				return item.name === label;
			}).value;
			if (value > 999) {
				var suffixes = ["", "k", "M", "B", "T"];
				var suffixNum = Math.floor(("" + value).length / 3);
				var shortValue = parseFloat(
					(suffixNum != 0
						? value / Math.pow(1000, suffixNum)
						: value
					).toPrecision(2),
				);
				if (shortValue % 1 != 0) {
					var shortNum = shortValue.toFixed(1);
				}
				return shortValue + suffixes[suffixNum];
			} else {
				return value;
			}
		} else {
			return 0;
		}
	}
	findLabelValue(data, label) {
		if (!_.isEmpty(data)) {
			var value = _.find(data, function (item) {
				return item.name === label;
			}).value;
			return value.toLocaleString();
		} else {
			return 0;
		}
	}
	getData(filter, userFilters) {
		let spliceData = "";
		let returnData = "";
		spliceData = userFilters.filter(function (item, index) {
			return item.split("=")[0] === filter;
		});
		returnData = spliceData.length ? spliceData[0].split("=") : "";
		if (returnData && returnData[1]) {
			return returnData[1].replace("%26", "&");
		} else {
			return "";
		}
	}
	ChangeFormateDate(oldDate, divider) {
		return oldDate.toString().split("-").reverse().join(divider);
	}
	ChangeDivider(date) {
		return date.toString().split("-").join(".");
	}
	generateReportHeader() {
		let reportHeader = "",
			headerJoin = "",
			locationName = "",
			fromDate = "",
			toDate = "",
			domain = "";
		let userFilters = this.props.match.params.query.split("&");
		locationName = this.getData("locationName", userFilters);
		fromDate = this.getData("fromDate", userFilters);
		toDate = this.getData("toDate", userFilters);
		domain = this.getData("domain", userFilters);
		//change date formate
		fromDate =
			domain == "reviews.10thousandfeet.com"
				? this.ChangeFormateDate(fromDate, ".")
				: this.ChangeDivider(fromDate);
		toDate =
			domain == "reviews.10thousandfeet.com"
				? this.ChangeFormateDate(toDate, ".")
				: this.ChangeDivider(toDate);
		//
		//
		if (locationName) {
			headerJoin = fromDate + " - " + toDate;
			reportHeader = (
				<div className="report-header">
					<span className="header-location">{locationName}</span>
					<span className="report-filter-capitalize">{headerJoin}</span>
				</div>
			);
		}
		return reportHeader;
	}
	generateReportFilters() {
		let reportFilters = [],
			locationName = "",
			domain = "";
		let userFilters = this.props.match.params.query.split("&");
		locationName = this.getData("locationName", userFilters);
		domain = this.getData("domain", userFilters);
		let dateFilters = userFilters.filter(function (item, index) {
			return (
				item.split("=")[0] === "fromDate" || item.split("=")[0] === "toDate"
			);
		});
		let starRatingFilter = userFilters.filter(function (item, index) {
			return (
				item.split("=")[0] === "starType" || item.split("=")[0] === "starValue"
			);
		});
		let remainingFilters = userFilters.filter(function (item, index) {
			return (
				item.split("=")[0] !== "fromDate" &&
				item.split("=")[0] !== "toDate" &&
				item.split("=")[0] !== "starType" &&
				item.split("=")[0] !== "starValue"
			);
		});
		// console.log("dashfilters", dateFilters, remainingFilters)
		function rotate(array, times) {
			while (times--) {
				var temp = array.shift();
				array.push(temp);
			}
		}
		rotate(remainingFilters, 3);
		remainingFilters.map((item, index) => {
			let filter = item.split("=");
			if (fieldsMap.hasOwnProperty(filter[0]) && filter[1] && filter[1]) {
				reportFilters.push(
					<div style={{ display: "inline-block", marginRight: 5 }}>
						<span className="report-filter-capitalize">
							{fieldsMap[filter[0]]}:{" "}
						</span>
						<span className="report-filter-capitalize">
							{
								// (filter[0] == 'starType' ?
								// 	fieldsMap[filter[1]] :
								// 	filter[1]) +
								// (index == this.props.match.params.query.split('&').length - 2 ?
								// 	"" :
								// 	",")
								decodeURIComponent(filter[1]) +
									(index == this.props.match.params.query.split("&").length - 2
										? ""
										: ",")
							}
						</span>
					</div>,
				);
			}
		});
		if (!locationName) {
			dateFilters.map((item, index) => {
				let filter = item.split("=");
				if (fieldsMap.hasOwnProperty(filter[0]) && filter[1]) {
					reportFilters.push(
						<div style={{ display: "inline-block", marginRight: 5 }}>
							<span className="report-filter-capitalize">
								{fieldsMap[filter[0]]}:{" "}
							</span>
							<span className="report-filter-capitalize">
								{(filter[0] == "starType"
									? fieldsMap[filter[1]]
									: domain == "reviews.10thousandfeet.com"
									? this.ChangeFormateDate(filter[1], "-")
									: filter[1]) +
									(index == this.props.match.params.query.split("&").length - 2
										? ""
										: ",")}
							</span>
						</div>,
					);
				}
			});
		}
		if (starRatingFilter.length == 2) {
			reportFilters.push(
				<div style={{ display: "inline-block", marginRight: 5 }}>
					<span className="report-filter-capitalize">
						{fieldsMap[starRatingFilter[0].split("=")[0]]}:{" "}
					</span>
					<span className="report-filter-capitalize">{`${
						fieldsMap[starRatingFilter[0].split("=")[1]]
					} ${starRatingFilter[1].split("=")[1]}`}</span>
				</div>,
			);
		}
		return reportFilters;
	}

	render() {
		let commentGraphData = this.state.commentsCountData;
		let commnetLenghtGraphData = this.state.commentWordCountData;
		var payLod = [
			{
				value: languageFile[localStorage.getItem("language")]["10010"],
				id: "1",
				color: "#f89670",
				type: "square",
			},
			{
				value: languageFile[localStorage.getItem("language")]["10013"],
				id: "2",
				color: "#fdbd60",
				type: "square",
			},
			{
				value: languageFile[localStorage.getItem("language")]["10016"],
				id: "3",
				color: "#dadfe4",
				type: "square",
			},
			{
				value: languageFile[localStorage.getItem("language")]["10019"],
				id: "4",
				color: "#7ad7a1",
				type: "square",
			},
			{
				value: languageFile[localStorage.getItem("language")]["10022"],
				color: "#6bcbee",
				type: "square",
				id: "5",
			},
			{
				value: languageFile[localStorage.getItem("language")]["10237"],
				id: "avg",
				color: "#8884D8",
				type: "line",
			},
		];
		var url = require("url");
		var adr = window.location.href;
		var parseUrlName = url.parse(adr, true);
		var urlName = parseUrlName.host;
		if (this.state.loading) {
			return (
				<div className="loader-main">
					<div className="loader"></div>
				</div>
			);
		}
		var emptyStates = [
			"QUEUE",
			"NO_ACCOUNTS_AUTHORIZED",
			"NO_ACCOUNTS_ACTIVATED",
			"FETCHING_NO_DATA",
			"FETCHING_LOCATIONS_NO_DATA",
		];

		if (emptyStates.indexOf(this.state.dataRetreivalStatus) != -1) {
			return (
				<EmptyStates
					status={this.state.dataRetreivalStatus}
					fetchingStatusData={this.state.fetchingStatusData}
				/>
			);
		}

		if (this.state.data && this.state.data.length === 0) {
			var noDataFlag = true;
		}

		return (
			<div
				className="dashboard-outer"
				style={{ background: "#fff" }}>
				<div className="print-header">
					<div className="print-logo">
						<img
							src={
								config.logoBaseUrl +
								"/" +
								localStorage.getItem("profileId") +
								"/logo.png?time=" +
								Date.now()
							}
							onError={(e) =>
								(e.target.src = `/images/${urlName}/logo-text.png`)
							}
						/>
					</div>
				</div>
				<div className="dashboard">
					<div className='report-options'>
					{this.generateReportHeader()}
					{this.generateReportFilters()}
					</div>
					
					<div className={styles.header}>
						<h2 className={styles.title}>
							{languageFile[localStorage.getItem("language")]["30811"]}
						</h2>
					</div>
					<div className={styles.dashboard}>
						<div className={styles.metricBlock}>
							<MetricCard
								loader={false}
								title={METRIC_CARD_CONSTANTS.REVIEWS}
								info={TOOLTIP_CONSTANTS.REVIEWS_INFO}
								value={
									this.state.metricData &&
									this.state.metricData.reviews &&
									this.state.metricData.reviews.reviewCount
										? this.state.metricData.reviews.reviewCount
										: 0
								}
								avgLabel={
									languageFile[localStorage.getItem("language")]["30813"]
								}
								avgValue={
									this.state.metricData &&
									this.state.metricData.reviews &&
									this.state.metricData.reviews.avgReviews
										? this.state.metricData.reviews.avgReviews
										: 0
								}
								changePercent={
									this.state.metricData &&
									this.state.metricData.reviews &&
									this.state.metricData.reviews.trendPercent
										? this.state.metricData.reviews.trendPercent
										: 0
								}
								dateInterval={""}
								width={'47%'}
							/>
							<MetricCard
								loader={false}
								title={METRIC_CARD_CONSTANTS.AVG_RATING}
								info={TOOLTIP_CONSTANTS.AVG_RATING_INFO}
								value={
									this.state.metricData &&
									this.state.metricData.rating &&
									this.state.metricData.rating.averageRating
										? this.state.metricData.rating.averageRating
										: 0
								}
								avgLabel={
									languageFile[localStorage.getItem("language")]["30815"]
								}
								avgValue={
									this.state.metricData &&
									this.state.metricData.rating &&
									this.state.metricData.rating.positiveRatingPercent
										? this.state.metricData.rating.positiveRatingPercent + "%"
										: 0
								}
								changePercent={
									this.state.metricData &&
									this.state.metricData.rating &&
									this.state.metricData.rating.trendPercent
										? this.state.metricData.rating.trendPercent
										: 0
								}
								dateInterval={""}
								width={'47%'}
							/>
							<MetricCard
								loader={false}
								title={METRIC_CARD_CONSTANTS.HAPPINESS}
								info={TOOLTIP_CONSTANTS.HAPPINESS_SCORE_INFO}
								value={
									this.state.metricData &&
									this.state.metricData.happiness &&
									this.state.metricData.happiness.happinessRate
										? Math.round(this.state.metricData.happiness.happinessRate)
										: 0
								}
								changePercent={
									this.state.metricData &&
									this.state.metricData.happiness &&
									this.state.metricData.happiness.trendPercent
										? this.state.metricData.happiness.trendPercent
										: 0
								}
								additionalInfo={
									(this.state.metricData &&
									this.state.metricData.happiness &&
									this.state.metricData.happiness.positiveRatingPercent
										? this.state.metricData.happiness.positiveRatingPercent
										: 0) +
									"-" +
									(this.state.metricData &&
									this.state.metricData.happiness &&
									this.state.metricData.happiness.neutralRatingPercent
										? this.state.metricData.happiness.neutralRatingPercent
										: 0) +
									"-" +
									(this.state.metricData &&
									this.state.metricData.happiness &&
									this.state.metricData.happiness.negativeRatingPercent
										? this.state.metricData.happiness.negativeRatingPercent
										: 0)
								}
								dateInterval={""}
								width={'47%'}
							/>

							<MetricCard
								loader={false}
								title={METRIC_CARD_CONSTANTS.WORD_RATIO}
								info={TOOLTIP_CONSTANTS.WORD_RATIO_INFO}
								value={
									this.state.metricData &&
									this.state.metricData.wordRatio &&
									this.state.metricData.wordRatio.postiveWordRatio
										? this.state.metricData.wordRatio.postiveWordRatio + "%"
										: "0%"
								}
								additionalInfo={
									(this.state.metricData &&
									this.state.metricData.wordRatio &&
									this.state.metricData.wordRatio.wordPercentByRating &&
									this.state.metricData.wordRatio.wordPercentByRating[0]
										? this.state.metricData.wordRatio.wordPercentByRating[0]
												.wordPercent
										: 0) +
									"-" +
									(this.state.metricData &&
									this.state.metricData.wordRatio &&
									this.state.metricData.wordRatio.wordPercentByRating[1]
										? this.state.metricData.wordRatio.wordPercentByRating[1]
												.wordPercent
										: 0) +
									"-" +
									(this.state.metricData &&
									this.state.metricData.wordRatio &&
									this.state.metricData.wordRatio.wordPercentByRating[2]
										? this.state.metricData.wordRatio.wordPercentByRating[2]
												.wordPercent
										: 0) +
									"-" +
									(this.state.metricData &&
									this.state.metricData.wordRatio &&
									this.state.metricData.wordRatio.wordPercentByRating[3]
										? this.state.metricData.wordRatio.wordPercentByRating[3]
												.wordPercent
										: 0) +
									"-" +
									(this.state.metricData &&
									this.state.metricData.wordRatio &&
									this.state.metricData.wordRatio.wordPercentByRating[4]
										? this.state.metricData.wordRatio.wordPercentByRating[4]
												.wordPercent
										: 0)
								}
								changePercent={
									this.state.metricData &&
									this.state.metricData.wordRatio &&
									this.state.metricData.wordRatio.trendPercent
										? this.state.metricData.wordRatio.trendPercent
										: 0
								}
								dateInterval={""}
								width={'47%'}
							/>
							<MetricCard
								loader={false}
								title={METRIC_CARD_CONSTANTS.RESPONSE_RATIO}
								info={TOOLTIP_CONSTANTS.RESPONSE_RATIO_INFO}
								value={
									this.state.metricData &&
									this.state.metricData.responseRatio &&
									this.state.metricData.responseRatio.responseRatio
										? this.state.metricData.responseRatio.responseRatio + "%"
										: "0%"
								}
								avgLabel={
									languageFile[localStorage.getItem("language")]["30813"]
								}
								avgValue={
									this.state.metricData &&
									this.state.metricData.responseRatio &&
									this.state.metricData.responseRatio.responseTime
										? this.state.metricData.responseRatio.responseTime + " days"
										: "0 days"
								}
								changePercent={
									this.state.metricData &&
									this.state.metricData.responseRatio &&
									this.state.metricData.responseRatio.trendPercent
										? this.state.metricData.responseRatio.trendPercent
										: 0
								}
								dateInterval={""}
								width={'47%'}
							/>
						</div>
						<div className={styles.graphBlock}>
							<ReviewSourcesCard
								sources={this.state.reviewSourceData}
								loader={false}
								width={'47%'}
							/>
							<RatingGraphWrapper
								id={"ratingChart"}
								data={this.state.starRatingsData}
								label={languageFile[localStorage.getItem("language")]["30820"]}
								tooltipText="Reviews"
								loader={false}
								// dateRange={filters.dateRange}
								width={'47%'}
							/>
							<RatingGraphWrapper
								id={"responseChart"}
								data={this.state.responseByRating}
								label={languageFile[localStorage.getItem("language")]["30821"]}
								tooltipText="Responses"
								loader={false}
								width={'47%'}
							/>
							<span className={styles.border}></span>
						</div>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<div className="page-break"></div>

						<div className={styles.reviewsTable}>
							<ReviewReviewChartWrapper
								loader={false}
								label={languageFile[localStorage.getItem("language")]["30822"]}
								data={this.state.recentReviewData}
								desc={RECENT_CHART_DESC.CHART_TOOLTIP(
									this.state.recentCount,
									this.state.dateInterval,
								)}
							/>
						</div>
						<span className={styles.border}></span>
						<div className="page-break"></div>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<div className={styles.reviewsTable}>
							<DashboardTableWrapper
								loader={false}
								label={languageFile[localStorage.getItem("language")]["30834"]}
								data={this.state.reviewAverageData}
								desc={languageFile[localStorage.getItem("language")]["30835"]}>
								<StackedBarLineComposedChart
									id="stackedbarandline"
									chartData={this.state.reviewAverageData}
									stackedBarChartData={[]}
									isShowDownload={true}
								/>
							</DashboardTableWrapper>
							<span className={styles.border}></span>
							<div className="page-break"></div>
							<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>	

							<DashboardTableWrapper
								loader={false}
								data={this.state.reviewResponseHistogramData}
								label={languageFile[localStorage.getItem("language")]["30859"]}
								desc={languageFile[localStorage.getItem("language")]["30860"]}>
								<ResponseTimeBarChart
									id="responsetimegraph"
									chartData={this.state.reviewResponseHistogramData}
									colourMap={colourMap}
									downloadHeader={{
										review: "review",
										"0-4": "< 4 Hour",
										"4-12": "4-12 Hours",
										"12-24": "12-24 Hours",
										"24-48": "2 Days",
										"48-72": "3 Days",
										"72-96": "4 days",
										"96-144": "5-6 Days",
										"144-192": "7-8 Days",
										"192-336": "9-14 Days",
										"336+": "14+ Days",
									}}
									ResponseReferenceHistogram={
										this.state.reviewResponseHistogramTimeData
									}
								/>
							</DashboardTableWrapper>

							<span className={styles.border}></span>
						</div>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
					
						<div className="page-break"></div>

						<div className={styles.graphBlock}>
							<CommentsGraphWrapper
								key={commentGraphData.length}
								data={commentGraphData}
								loader={false}
								desc={languageFile[localStorage.getItem("language")]["30870"]}
								label={languageFile[localStorage.getItem("language")]["30866"]}
								width={'47%'}
								>
								<CommentRatioChart
									id="commentRatioChart"
									chartData={commentGraphData}
									showDownLoad={false}
								/>
							</CommentsGraphWrapper>
							<CommentsGraphWrapper
								key={commnetLenghtGraphData}
								id="commentLengthChart"
								data={commnetLenghtGraphData}
								loader={false}
								desc={languageFile[localStorage.getItem("language")]["30871"]}
								label={languageFile[localStorage.getItem("language")]["30867"]}
								width={'47%'}
							>

								<CommentLengthChart
									key={this.state.commentsCountData.length + 2}
									id={commnetLenghtGraphData + 2}
									chartData={commnetLenghtGraphData}
									showDownLoad={false}
								/>
							</CommentsGraphWrapper>
							</div>
							<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<div className={styles.graphBlock}>

							<CommentsGraphWrapper
								key={this.state.commentsCountData.length + 3}
								data={this.state.commentWordCountData}
								loader={false}
								desc={languageFile[localStorage.getItem("language")]["30872"]}
								label={languageFile[localStorage.getItem("language")]["30817"]}
								width={'47%'}

								>
								<CommentWordCountChart
									chartData={this.state.commentWordCountData}
									showDownLoad={true}
								/>
							</CommentsGraphWrapper>
							<span className={styles.border}></span>
						</div>
						<div className="page-break"></div>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<br></br>


						<div className={styles.reviewsTable}>
							<DashboardReviewWaveChartWrapper
								loader={false}
								label={languageFile[localStorage.getItem("language")]["30886"]}
								data={this.state.waveCharatLocationData}
								showChartZoom={false}
								onChangeZoomChartView={() => {}}
								desc={languageFile[localStorage.getItem("language")]["30887"]}>
								<ReviewRatingWaveChart
									nodeColorMap={this.state.nodeColorMap}
									showBrandChart={true}
									showChartZoom={false}
									waveGraphData={this.state.waveCharatLocationData}
								/>
							</DashboardReviewWaveChartWrapper>
						</div>
					</div>
					<div className="nightmare-check-stacked-bar nightmare-check-stacked-area nightmare-check-stacked-line"></div>
				</div>
			</div>
		);
	}
}

export default DashboardReviewPdf;
