import config from "../../../../globalconfig";
import axios from "axios";
import cookie from "../../../utils/cookie";

var APIClient = function () {};

APIClient.prototype = {
	getAggregatedReplyRatingCount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getAggregatedReplyRatingCount",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {

		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getAggregatedReplyRatingCount", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getFacebookReviewComments: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getFacebookReviewComments",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getFacebookReviewComments", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getProfanityCheck: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getProfanityCheck?searchTerm=" + data.searchTerm + "&profileId=" + data.profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getProfanityCheck?searchTerm=" +
					data.searchTerm +
					"&profileId=" +
					data.profileId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	commentFacebookReview: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getProfanityCheck?searchTerm=" + data.searchTerm + "&profileId=" + data.profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getProfanityCheck?searchTerm=" +
					data.searchTerm +
					"&profileId=" +
					data.profileId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getSavedReplyGroups: function (profileId, searchTerm, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getSavedReplyGroups?profileId=" + profileId + "&searchTerm=" + searchTerm,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getSavedReplyGroups?profileId=" +
					profileId +
					"&searchTerm=" +
					searchTerm,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateReport: function (data, callback) {
		axios
			.post(config.server + "/updateReportConfiguration", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteReport: function (profileId, reportId, callback) {
		axios
			.get(
				config.server +
					"/deleteReportConfiguration?profileId=" +
					profileId +
					"&reportId=" +
					reportId,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteLocationTag: function (profileId, type, filterTagId, callback) {
		axios
			.get(
				config.server +
					"/deleteFilterTag?profileId=" +
					profileId +
					"&type=" +
					type +
					"&filterTagId=" +
					filterTagId,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReportById: function (profileId, reportId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReportConfigurationById?profileId=" + profileId + "&reportId=" + reportId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getReportConfigurationById?profileId=" +
					profileId +
					"&reportId=" +
					reportId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReport: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getReportConfiguration", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReviewsOverview: function (data, callback) {
		axios
			.post(config.server + "/getReviewsOverview", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReviewSummaryReport: function (data, callback) {
		axios
			.post(config.server + "/getReviewSummaryReport", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getSentimentOverview: function (data, callback) {
		axios
			.post(config.server + "/getSentimentOverview", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getSentimentByTopic: function (data, callback) {
		axios
			.post(config.server + "/getSentimentByTopic", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getRecentReviews: function (data, callback) {
		axios
			.post(config.server + "/getRecentReviews", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	zohoDeskSignUp: function (email, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/zohoDeskSignUp",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(email),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/zohoDeskSignUp", email, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getReviewAnalysis: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReviewAnalysis",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getReviewAnalysis", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getCategories: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReviewTags",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getReviewTags", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	addNewCategory: function (data, callback) {
		axios
			.post(config.server + "/addOrphanReviewTag", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	delFullCategory: function (data, callback) {
		axios
			.post(config.server + "/deleteReviewTagFromProfile", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editFullCategory: function (data, callback) {
		axios
			.post(config.server + "/editProfileReviewTag", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	addCategory: function (data, callback) {
		axios
			.post(config.server + "/addReviewTags", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	delCategory: function (data, callback) {
		axios
			.post(config.server + "/deleteReviewTags", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getSentimentReview: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getTopicBasedSentimentReviews",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getTopicBasedSentimentReviews", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getDatewiseSentimentHistogram: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getDatewiseSentimentHistogram",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getDatewiseSentimentHistogram", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getGoogleLocations: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getGoogleLocationInsights",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getGoogleLocationInsights", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getAllLocationsHistogram: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/fetchedAllLocationsHistogram",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/fetchedAllLocationsHistogram", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	fetchedLocationsHistogram: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/fetchedLocationsHistogram",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/fetchedLocationsHistogram", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	downloadInvoice: function (invoiceId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/downloadInvoiceAsPdf?invoiceId=" + invoiceId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/downloadInvoiceAsPdf?invoiceId=" + invoiceId, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	listInvoices: function (profileId, limit, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/listInvoices?profileId=" + profileId + '&limit=10',
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server + "/listInvoices?profileId=" + profileId + "&limit=100",
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	deleteSubscription: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/deleteSubscription?profileId=" + profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/deleteSubscription?profileId=" + profileId, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	cancelSubscription: function (profileId, callback) {
		axios
			.get(config.server + "/cancelSubscription?profileId=" + profileId, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	refreshAccount: function (data, callback) {
		axios
			.post(config.server + "/refreshAccount", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	disableAccount: function (data, callback) {
		axios
			.get(
				config.server +
					"/deleteAccount?profileId=" +
					data.profileId +
					"&accountId=" +
					data.accountId,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	logout: function (token, callback) {
		// $.ajax({
		//     url: config.server + "/logout",
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/logout", {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	updatePaymentOwner: function (profileId, userId, callback) {
		axios
			.get(
				config.server +
					"/updatePaymentOwner?profileId=" +
					profileId +
					"&updateUserId=" +
					userId,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getPaymentDetails: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getPaymentDetails?profileId=" + profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/getPaymentDetails?profileId=" + profileId, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	//     getProfileLocationCount: function (profileId, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getProfileLocationCount?profileId=" + profileId,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	getHostPage: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getHostPagePayment?profileId=" + profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/getHostPagePayment?profileId=" + profileId, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	// zohoDeskSignUp: function (email, callback) {
	//     var token = cookie.readCookie("lctoken");
	//     $.ajax({
	//         url: config.server + "/zohoDeskSignUp",
	//         type: 'POST',
	//         headers: {
	//             "Token": token,
	//             "Content-Type": "application/json"
	//         },
	//         contentType: "application/json",
	//         data: JSON.stringify(email),
	//         success: function (resp) {
	//             callback(null, resp)
	//         },
	//         error: function (err) {
	//             console.log("Search Results: Ajax error ", err);
	//             callback(err.response, null)
	//         }
	//     });
	// },

	//     getReviewAnalysis: function (data, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getReviewAnalysis",
	//             type: 'POST',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             data: JSON.stringify(data),
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },
	//     getCategories: function (data, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getReviewTags",
	//             type: 'POST',
	//             contentType: "application/json",
	//             dataType: 'JSON',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             data: JSON.stringify(data),
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null);
	//             }
	//         });
	//     },
	//     addNewCategory: function (data, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/addOrphanReviewTag",
	//             type: 'POST',
	//             contentType: "application/json",
	//             dataType: 'JSON',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             data: JSON.stringify(data),
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null);
	//             }
	//         });
	//     },
	//     delFullCategory: function (data, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/deleteReviewTagFromProfile",
	//             type: 'POST',
	//             contentType: "application/json",
	//             dataType: 'JSON',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             data: JSON.stringify(data),
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null);
	//             }
	//         });
	//     },
	//     editFullCategory: function (data, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/editProfileReviewTag",
	//             type: 'POST',
	//             contentType: "application/json",
	//             dataType: 'JSON',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             data: JSON.stringify(data),
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null);
	//             }
	//         });
	//     },
	//     addCategory: function (data, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/addReviewTags",
	//             type: 'POST',
	//             contentType: "application/json",
	//             dataType: 'JSON',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             data: JSON.stringify(data),
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null);
	//             }
	//         });
	//     },
	//     delCategory: function (data, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/deleteReviewTags",
	//             type: 'POST',
	//             contentType: "application/json",
	//             dataType: 'JSON',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             data: JSON.stringify(data),
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null);
	//             }
	//         });
	//     },
	//     getSentimentReview: function (data, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getTopicBasedSentimentReviews",
	//             type: 'POST',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             data: JSON.stringify(data),
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },
	//     getDatewiseSentimentHistogram: function (data, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getDatewiseSentimentHistogram",
	//             type: 'POST',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             data: JSON.stringify(data),
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	//     getGoogleLocations: function (data, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getGoogleLocationInsights",
	//             type: 'POST',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             data: JSON.stringify(data),
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	//     getAllLocationsHistogram: function (data, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/fetchedAllLocationsHistogram",
	//             type: 'POST',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             data: JSON.stringify(data),
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	//     fetchedLocationsHistogram: function (data, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/fetchedLocationsHistogram",
	//             type: 'POST',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             data: JSON.stringify(data),
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	//     downloadInvoice: function (invoiceId, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/downloadInvoiceAsPdf?invoiceId=" + invoiceId,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	//     listInvoices: function (profileId, limit, callback) {

	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/listInvoices?profileId=" + profileId + '&limit=10',
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	//     deleteSubscription: function (profileId, callback) {

	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/deleteSubscription?profileId=" + profileId,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	//     cancelSubscription: function (profileId, callback) {

	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/cancelSubscription?profileId=" + profileId,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	//     disableAccount: function (data, callback) {

	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/deleteAccount?profileId=" + data.profileId + "&accountId=" + data.accountId,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	//     logout: function (token, callback) {
	//         $.ajax({
	//             url: config.server + "/logout",
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	//     updatePaymentOwner: function (profileId, userId, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/updatePaymentOwner?profileId=" + profileId + "&updateUserId=" + userId,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	//     getPaymentDetails: function (profileId, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getPaymentDetails?profileId=" + profileId,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	//     getProfileLocationCount: function (profileId, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getProfileLocationCount?profileId=" + profileId,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	//     getHostPage: function (profileId, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getHostPagePayment?profileId=" + profileId,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	resetUserPassword: function (profileId, userId, urlName, callback) {
		var token = cookie.readCookie("lctoken");
		let data = {
			profileId: profileId,
			userId: userId,
			domainName: urlName,
		};
		// $.ajax({
		//     url: config.server + "/resetPasswordRequestOwner?profileId=" + profileId + "&userId=" + userId + "&domainName=" + urlName,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/resetPasswordRequestOwner", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getRoleDetails: function (profileId, roleId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getRoleDetails?profileId=" + profileId + "&roleId=" + roleId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getRoleDetails?profileId=" +
					profileId +
					"&roleId=" +
					roleId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	assignUserstoRole: function (data, callback) {
		axios
			.post(config.server + "/updateProfileUserRole", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getUserDetails: function (profileId, userId, domain, callback) {
		let data = {
			profileId: profileId,
			userId: userId,
			domainName: domain,
		};
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getUserDetails?profileId=" + profileId + "&userId=" + userId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getUserDetails", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getRoleUsers: function (profileId, role, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getRoleUsers?profileId=" + profileId + "&roleName=" + role,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getRoleUsers?profileId=" +
					profileId +
					"&roleName=" +
					role,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	deleteRole: function (profileId, roleId, callback) {
		axios
			.get(
				config.server +
					"/deleteRole?profileId=" +
					profileId +
					"&roleId=" +
					roleId,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getRoles: function (profileId, page, size, searchTerm, callback) {
		var url =
			"/getRoles?profileId=" + profileId + "&from=" + page + "&size=" + size;
		if (searchTerm) {
			url += "&searchTerm=" + searchTerm;
		}
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + url,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + url, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	createRole: function (data, callback) {
		axios
			.post(config.server + "/createRole", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	createReport: function (data, callback) {
		axios
			.post(config.server + "/saveReportConfiguration", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getPaymentUrl: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getPaymentUrl?profileId=" + profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/getPaymentUrl?profileId=" + profileId, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	revertEdits: function (docId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/revertEdits?docId=" + docId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/revertEdits?docId=" + docId, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getLocationUpdateHistoryDiff: function (profileId, locationId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocationUpdateHistoryDiff?profileId=" + profileId + "&locationId=" + locationId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getLocationUpdateHistoryDiff?profileId=" +
					profileId +
					"&locationId=" +
					locationId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getCategoryList: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getCategoryList",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getCategoryList", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getCategoryEditList: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getAllCategories", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	editLocation: function (data, callback) {
		axios
			.post(config.server + "/editLocation", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editLocationDetails: function (data, callback) {
		axios
			.post(config.server + "/editLocationDetails", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getFacebookInsightsHistogram: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getFacebookInsightsHistogram",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getFacebookInsightsHistogram", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getFacebookReviewHistogram: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getFacebookReviewHistogram",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getFacebookReviewHistogram", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	fetchFacebookData: function (profileId, pageId, callback) {
		var token = cookie.readCookie("lctoken");
		//         $.ajax({
		//             url: config.server + "/fetchFacebookData?profileId=" + profileId + "&pageId=" + pageId,
		//             type: 'GET',
		//             headers: {
		//                 "Token": token,
		//                 "Content-Type": "application/json"
		//             },
		//             contentType: "application/json",
		//             success: function (resp) {
		//                 callback(null, resp)
		//             },
		//             error: function (err) {
		//                 console.log("Search Results: Ajax error ", err);
		//                 callback(err.response, null)
		//             }
		//         });
		axios
			.get(
				config.server +
					"/fetchFacebookData?profileId=" +
					profileId +
					"&pageId=" +
					pageId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	disableFacebookData: function (profileId, pageId, callback) {
		var token = cookie.readCookie("lctoken");
		//         $.ajax({
		//             url: config.server + "/deleteFacebookPage?profileId=" + profileId + "&pageId=" + pageId,
		//             type: 'GET',
		//             headers: {
		//                 "Token": token,
		//                 "Content-Type": "application/json"
		//             },
		//             contentType: "application/json",
		//             success: function (resp) {
		//                 callback(null, resp)
		//             },
		//             error: function (err) {
		//                 console.log("Search Results: Ajax error ", err);
		//                 callback(err.response, null)
		//             }
		//         });
		axios
			.get(
				config.server +
					"/deleteFacebookPage?profileId=" +
					profileId +
					"&pageId=" +
					pageId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	updateProfileUsers: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/updateProfileUsers",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/updateProfileUsers", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	deleteUserFromProfile: function (data, callback) {
		let accountId = cookie.readCookie("currentAccountId");
		if (accountId && accountId != null) {
			data.accountId = accountId;
		}
		axios
			.post(config.server + "/deleteUserFromProfile", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	//     getFacebookReview: function (profileId, fromPage, size, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getFacebookReview?profileId=" + profileId + "&from=" + fromPage + "&size=" + size,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	sendHelpEmail: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/sendHelpEmail",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/sendHelpEmail", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getFacebookPages: function (profileId, fromPage, size, ishidden, callback) {
		var token = cookie.readCookie("lctoken");
		//         $.ajax({
		//             url: config.server + "/getFacebookPages?profileId=" + profileId + "&from=" + fromPage + "&size=" + size,
		//             type: 'GET',
		//             headers: {
		//                 "Token": token,
		//                 "Content-Type": "application/json"
		//             },
		//             contentType: "application/json",
		//             success: function (resp) {
		//                 callback(null, resp)
		//             },
		//             error: function (err) {
		//                 console.log("Search Results: Ajax error ", err);
		//                 callback(err.response, null)
		//             }
		//         });
		axios
			.get(
				config.server +
					"/getFacebookPages?profileId=" +
					profileId +
					"&from=" +
					fromPage +
					"&size=" +
					size +
					"&isHidden=" +
					ishidden,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getFacebookSync: function (profileId, pageId, callback) {
		var token = cookie.readCookie("lctoken");
		//         $.ajax({
		//             url: config.server + "/getFacebookPages?profileId=" + profileId + "&from=" + fromPage + "&size=" + size,
		//             type: 'GET',
		//             headers: {
		//                 "Token": token,
		//                 "Content-Type": "application/json"
		//             },
		//             contentType: "application/json",
		//             success: function (resp) {
		//                 callback(null, resp)
		//             },
		//             error: function (err) {
		//                 console.log("Search Results: Ajax error ", err);
		//                 callback(err.response, null)
		//             }
		//         });
		axios
			.get(
				config.server +
					"/refreshFacebookPage?profileId=" +
					profileId +
					"&pageId=" +
					pageId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getTripadvisorPages: function (profileId, fromPage, size, callback) {
		var token = cookie.readCookie("lctoken");
		//         $.ajax({
		//             url: config.server + "/getTripAdvisorPages?profileId=" + profileId + "&from=" + fromPage + "&size=" + size,
		//             type: 'GET',
		//             headers: {
		//                 "Token": token,
		//                 "Content-Type": "application/json"
		//             },
		//             contentType: "application/json",
		//             success: function (resp) {
		//                 console.log("api Ajax getTripadvisorPages ", resp);
		//                 callback(null, resp)
		//             },
		//             error: function (err) {
		//                 console.log("Search Results: Ajax error getTripadvisorPages ", err);
		//                 callback(err.response, null)
		//             }
		//         });
		axios
			.get(
				config.server +
					"/getTripAdvisorPages?profileId=" +
					profileId +
					"&from=" +
					fromPage +
					"&size=" +
					size,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	//     getYelpPages: function (profileId, fromPage, size, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getYelpPages?profileId=" + profileId + "&from=" + fromPage + "&size=" + size,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 console.log("api Ajax getyelpPages ", resp);
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error getyelpPages ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },
	//     getBookingPages: function (profileId, fromPage, size, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getBookingPages?profileId=" + profileId + "&from=" + fromPage + "&size=" + size,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 console.log("api Ajax getBookingPages ", resp);
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error getBookingPages ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },
	//     getCellarpassPages: function (profileId, fromPage, size, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getCellarpassPages?profileId=" + profileId + "&from=" + fromPage + "&size=" + size,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 console.log("api Ajax getcellarPages ", resp);
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error getcellerPages ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	//     getFoursquareVenueTips: function (profileId, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getFoursquareVenueTips?profileId=" + profileId + "&venueId=592648d3acc5f53df2aa3b64",
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	uploadLocationFilterTag: function (data, callback) {
		axios
			.post(config.server + "/uploadLocationFilterTag", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	compareDatesPerformance: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/compareDatesPerformance",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/compareDatesPerformance", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getLocations: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocations",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getLocations", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getLocationsByAccounts: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocationsByAccounts", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	deleteReplyTemplate: function (templateId, profileId, callback) {
		axios
			.get(
				config.server +
					"/deleteReplyTemplate?replyTemplateId=" +
					templateId +
					"&profileId=" +
					profileId,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	editReplyTemplate: function (data, callback) {
		axios
			.post(config.server + "/editReplyTemplate", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getGeneratedReply: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getGeneratedReply", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReplyTemplate: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReplyTemplates",
		//     type: 'POST',
		//     data: JSON.stringify(data),
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getReplyTemplates", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	createReplyTemplate: function (data, callback) {
		axios
			.post(config.server + "/createReplyTemplate", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	sendReplyForApproval: function (data, callback) {
		axios
			.post(config.server + "/sendReplyForApproval", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	//     getFoursquareManagedVenues: function (profileId, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getFoursquareManagedVenues?profileId=" + profileId,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	getUserToken: function (userId, domainName, callback) {
		let data = {
			userId: userId,
			domainName: domainName,
		};
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		// $.ajax({
		//     url: config.server + "/loginAsUser?userId=" + encodeURIComponent(userId),
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "AdminToken": adminToken,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/loginAsUser", data, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getFacebookAuthUrl: function (profileId, urlName, callback) {
		var token = cookie.readCookie("lctoken");
		//         $.ajax({
		//             url: config.server + "/getFacebookAuthUrl?profileId=" + profileId + '&domainName=' + urlName,
		//             type: 'GET',
		//             headers: {
		//                 "Token": token,
		//                 "Content-Type": "application/json"
		//             },
		//             contentType: "application/json",
		//             success: function (resp) {
		//                 callback(null, resp)
		//             },
		//             error: function (err) {
		//                 console.log("Search Results: Ajax error ", err);
		//                 callback(err.response, null)
		//             }
		//         });
		axios
			.get(
				config.server +
					"/getFacebookAuthUrl?profileId=" +
					profileId +
					"&domainName=" +
					urlName,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getFoursquareAuthUrl: function (profileId, urlName, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getFoursquareAuthUrl?profileId=" + profileId + '&domainName=' + urlName,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getFoursquareAuthUrl?profileId=" +
					profileId +
					"&domainName=" +
					urlName,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getTopLocations: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getTopLocationsReviews",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getTopLocationsReviews", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getUsageInfo: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		// $.ajax({
		//     url: config.server + "/getUsageInfo",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "AdminToken": adminToken,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getUsageInfo", data, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	checkAdmin: function (domain, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		// $.ajax({
		//     url: config.server + "/checkAdminAccess?domainName=" + domain,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "AdminToken": adminToken,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/checkAdminAccess?domainName=" + domain, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	deleteProfile: function (profileId, domain, callback) {
		axios
			.get(
				config.server +
					"/deleteProfile?profileId=" +
					profileId +
					"&domainName=" +
					domain,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	deleteSentReply: function (data, callback) {
		axios
			.post(config.server + "/deleteSentReply", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getNotes: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getNotes",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getNotes", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	//     deleteNote: function (replyId, noteId, profileId, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/deleteNote?profileId=" + profileId + "&replyId=" + replyId + "&noteId=" + noteId,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	createNote: function (data, callback) {
		axios
			.post(config.server + "/createNote", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	sendreplyFromOutbox: function (data, callback) {
		axios
			.post(config.server + "/sendReplyFromOutbox", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReplySentDocs: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReplySentDocs",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getReplySentDocs", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	moveReplyToOutbox: function (data, callback) {
		axios
			.post(config.server + "/moveReplyToOutbox", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReplyOutboxDocs: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReplyOutboxDocs",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getReplyOutboxDocs", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteReply: function (data, callback) {
		axios
			.post(config.server + "/deleteReply", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	sendReply: function (data, callback) {
		axios
			.post(config.server + "/sendReply", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReplyApprovalDocs: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReplyApprovalDocs",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getReplyApprovalDocs", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getEditors: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getEditors?profileId=" + profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/getEditors?profileId=" + profileId, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getProfileSettings: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getProfileSettings?profileId=" + profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/getProfileSettings?profileId=" + profileId, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	saveProfileSettings: function (data, callback) {
		axios
			.post(config.server + "/saveProfileSettings", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationSummaryHistogram: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocationSummaryHistogram",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getLocationSummaryHistogram", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getSentimentAnalysisScan: function (data, callback) {
		axios
			.post(config.server + "/getHistoricSentimentAnalysisStatus", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	runSentimentAnalysisScan: function (data, callback) {
		axios
			.post(config.server + "/performHistoricSentimentsAnalysis", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	compareLocationsHistogram: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/compareLocationsHistogram",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/compareLocationsHistogram", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getFilterTags: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		//         $.ajax({
		//             url: config.server + "/getFilterTags",
		//             type: 'POST',
		//             headers: {
		//                 "Token": token,
		//                 "Content-Type": "application/json"
		//             },
		//             contentType: "application/json",
		//             data: JSON.stringify(data),
		//             success: function (resp) {
		//                 callback(null, resp)
		//             },
		//             error: function (err) {
		//                 console.log("Search Results: Ajax error ", err);
		//                 callback(err.response, null)
		//             }
		//         });
		axios
			.post(config.server + "/getFilterTags", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	saveFilterTag: function (tagObject, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/saveFilterTag",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(tagObject),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/saveFilterTag", tagObject, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	updateEmail: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/updateEmailForProfile",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/updateEmailForProfile", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateReadReview: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     // url: config.server + "/updateReadReview?profileId=" + profileId + "&reviewId=" + reviewId + "&date=" + date,
		//     url: config.server + "/updateReadReview",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/updateReadReview", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getDraftReview: function (reviewDocId, date, source, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReview?reviewDocId=" + reviewDocId + "&date=" + date + "&source=" + source,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getReview?reviewDocId=" +
					reviewDocId +
					"&date=" +
					date +
					"&source=" +
					source,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getUnansweredReviews: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getAwaitingResponseReviews",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getAwaitingResponseReviews", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getDrafts: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReviewReplyDrafts",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getReviewReplyDrafts", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getBubbleCount: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReviewsTabBubbleCount",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getReviewsTabBubbleCount", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	saveDraft: function (data, callback) {
		axios
			.post(config.server + "/saveReplyAsDraft", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReviewsCount: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReviewsCount",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getReviewsCount", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReviewResponseOverview: function (data, callback) {
		axios
			.post(config.server + "/getReviewResponseOverview", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationTags: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		//         $.ajax({
		//             url: config.server + "/getLocationTags",
		//             type: 'POST',
		//             headers: {
		//                 "Token": token,
		//                 "Content-Type": "application/json"
		//             },
		//             contentType: "application/json",
		//             data: JSON.stringify(data),
		//             success: function (resp) {
		//                 callback(null, resp)
		//             },
		//             error: function (err) {
		//                 console.log("Search Results: Ajax error ", err);
		//                 callback(err.response, null)
		//             }
		//         });
		axios
			.post(config.server + "/getLocationTags", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	reviewTreelist: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");

		// $.ajax({
		//     url: config.server + "/getReviewsSummary",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getReviewsSummary", data, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	downloadTreelistExcel: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		// $.ajax({
		//     url: config.server + "/getReviewsSummaryCsv",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getReviewsSummaryCsv", data, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	adminPasswordReset: function (userDetails, callback) {
		var token = cookie.readCookie("superuser");
		// $.ajax({
		//     url: config.server + "/resetPasswordAdmin",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(userDetails),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/resetPasswordAdmin", userDetails, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	resetPassword: function (userDetails, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/resetPassword",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(userDetails),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/resetPassword", userDetails, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	resetPasswordRequest: function (userId, urlName, callback) {
		var token = cookie.readCookie("lctoken");
		let data = {
			userId: userId,
			domainName: urlName,
		};
		// $.ajax({
		//     url: config.server + "/resetPasswordRequest?userId=" + userId + "&domainName=" + urlName,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/resetPasswordRequest", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getDataFetchStatus: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getDataFetchingStatus?profileId=" + profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/getDataFetchingStatus?profileId=" + profileId, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getProfileDetails: function (profileId, domainName, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getProfileUserDetails?profileId=" + profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					`/getProfileUserDetails?profileId=${profileId}&domainName=${domainName}`,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getDataStatus: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getDataStatus?profileId=" + profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/getDataStatus?profileId=" + profileId, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	enableAccount: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/enableAccount?profileId=" + data.profileId + "&accountId=" + data.accountId + "&domainName=" + data.domainName,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/enableAccount?profileId=" +
					data.profileId +
					"&accountId=" +
					data.accountId +
					"&domainName=" +
					data.domainName,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getProfiles: function (userId, callback) {
		var token = cookie.readCookie("lctoken");
		if (userId) {
			token = userId;
		}
		// $.ajax({
		//     url: config.server + "/getProfiles",
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });

		axios
			.get(config.server + "/getProfiles", {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getProfilesDetails: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getProfileDetails", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getProfilesV2: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getProfilesV2", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getPaginatedPortfolios: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getPaginatedPortfolios", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	addProfile: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/createProfile/",
		//     type: 'POST',
		//     dataType: 'JSON',
		//     contentType: "application/json",
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)

		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });

		axios
			.post(config.server + "/createProfile/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	inviteUser: function (data, token, callback) {
		let accountId = cookie.readCookie("currentAccountId");
		if (accountId && accountId != null) {
			data.accountId = accountId;
		}
		// $.ajax({
		//     url: config.server + "/inviteUser/",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     dataType: 'JSON',
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)

		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/inviteUser/", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	// playStoreAuth: function(profileId,urlName,clientId,clientSecret,packageName,callback){
	playStoreAuth: function (data, callback) {
		var profileId = data.profileId;
		var clientId = data.clientId;
		var clientSecret = data.clientSecret;
		var packageName = data.packageName;
		var urlName = data.urlName;
		var TOKEN = cookie.readCookie("lctoken");

		axios
			.get(
				config.server +
					"/playStoreAuth?profileId=" +
					profileId +
					"&domainName=" +
					urlName +
					"&clientId=" +
					clientId +
					"&clientSecret=" +
					clientSecret +
					"&packageName=" +
					packageName,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	authoriseAccount: function (profileId, token, urlName, callback) {
		// $.ajax({
		//     url: config.server + "/mbAuth/?profileId=" + profileId + '&domainName=' + urlName,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/x-www-form-urlencoded"
		//     },
		//     contentType: "application/x-www-form-urlencoded",
		//     success: function (resp) {
		//         callback(null, resp)

		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/mbAuth/?profileId=" +
					profileId +
					"&domainName=" +
					urlName,
				{
					headers: {
						Token: token,
						"Content-Type": "application/x-www-form-urlencoded",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	authoriseAppleAccount: function (profileId, urlName, callback) {
		var adminToken = cookie.readCookie("superuser");
		var TOKEN = cookie.readCookie("lctoken");
		let headers = {
			Token: TOKEN,
			"Content-Type": "application/x-www-form-urlencoded",
		};

		if (adminToken) {
			headers["AdminToken"] = adminToken;
		}
		axios
			.get(
				config.server +
					"/getAppleAuthRequestUrl/?profileId=" +
					profileId +
					"&domainName=" +
					urlName,
				{
					headers: headers,
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	//     downloadPdf: function (url, callback) {
	//         console.log("utilsConfig", utilsConfig.server, url)
	//         $.ajax({
	//             url: utilsConfig.server + "downloadAsPdf",
	//             type: 'POST',
	//             dataType: 'JSON',
	//             contentType: "application/json",
	//             data: JSON.stringify(url),
	//             success: function (resp) {
	//                 callback(null, resp)

	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },

	login: function (userObject, callback) {
		// $.ajax({
		//     url: config.server + "/login/",
		//     type: 'POST',
		//     dataType: 'JSON',
		//     contentType: "application/json",
		//     data: JSON.stringify(userObject),
		//     success: function (resp) {
		//         callback(null, resp)

		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });

		axios
			.post(config.server + "/login/", userObject)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	signup: function (userObject, callback) {
		// $.ajax({
		//     url: config.server + "/signup/",
		//     type: 'POST',
		//     dataType: 'JSON',
		//     contentType: "application/json",
		//     data: JSON.stringify(userObject),
		//     success: function (resp) {
		//         callback(null, resp)

		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });

		axios
			.post(config.server + "/signup/", userObject)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	//     getReviewsWorldCloud: function (data, callback) {
	//         var TOKEN = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getReviewsWorldCloud/",
	//             type: 'POST',
	//             contentType: "application/json",
	//             dataType: 'JSON',
	//             headers: {
	//                 "Token": TOKEN,
	//                 "Content-Type": "application/json"
	//             },
	//             data: JSON.stringify(data),
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {

	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null);
	//             }
	//         });
	//     },

	getLocationInsightMetricCount: function (data, callback) {
		var test = JSON.parse(JSON.stringify(data));
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocationInsightMetricCount/",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {

		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getLocationInsightMetricCount/", test, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getLocationPerformanceSummaryList: function (data, callback) {
		axios
			.post(config.server + "/getLocationPerformanceSummaryList", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getLocationInsightHistogram: function (data, callback) {
		var test = JSON.parse(JSON.stringify(data));
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocationInsightHistogram/",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {

		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getLocationInsightHistogram/", test, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getLocationInsight: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		var test = JSON.parse(JSON.stringify(data));
		// $.ajax({
		//     url: config.server + "/getLocationInsight/",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {

		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getLocationInsight/", test, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getLocationStates: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocationStates/",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getLocationStates/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getReviewsTreemap: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReviewsTreemap/",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {

		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getReviewsTreemap/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	searchReviews: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/searchReviews/",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/searchReviews/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReviewSuggestions: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		var profileId = data.profileId;
		var masterLocationFilter = data.masterLocationFilter;
		var url =
			"/getReviewSuggestions?profileId=" +
			profileId +
			"&masterLocationFilter=" +
			masterLocationFilter;
		var country = data.country || "";
		var area = data.area || "";
		var countries = data.countries || "";

		if (countries) {
			url = url + "&country=" + countries;
		}
		// if(area){
		//     var locality = data.locality||"";
		//     url = url + '&locality='+locality;
		// }

		if (country) {
			url = url + "&country=" + country;
			var area = data.area || "";
		}
		if (area) {
			url = url + "&area=" + area;
			var locality = data.locality || "";
			url = url + "&locality=" + locality;
		}

		// $.ajax({
		//     url: config.server + url,
		//     type: 'GET',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {

		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.get(config.server + url, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getAggregatedRatingCount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getAggregatedRatingCount/",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {

		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getAggregatedRatingCount/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationRatings: function (data, callback) {
		axios
			.post(config.server + "/getLocationRatings/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getLocationReviewSummaryList: function (data, callback) {
		axios
			.post(config.server + "/getLocationReviewSummaryList", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getGridOverview: function (data, callback) {
		axios
			.post(config.server + "/getGridOverview/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getPerformanceOverview: function (data, callback) {
		axios
			.post(config.server + "/getPerformanceOverview/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getPerformanceSummaryReport: function (data, callback) {
		axios
			.post(config.server + "/getPerformanceSummaryReport/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getEnergyOverview: function (data, callback) {
		axios
			.post(config.server + "/getEnergyOverview/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getReviewHistogram: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReviewHistogram/",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {

		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getReviewHistogram/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getResponseHistogram: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getResponseHistogram", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	downloadReviewLocation: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReviewHistogramCsv/",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {

		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getReviewHistogramCsv/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReviews: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReviews/",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getReviews/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	//     getZomatoReviews: function (data, callback) {
	//         var TOKEN = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getZomatoReviews/",
	//             type: 'POST',
	//             contentType: "application/json",
	//             dataType: 'JSON',
	//             headers: {
	//                 "Token": TOKEN,
	//                 "Content-Type": "application/json"
	//             },
	//             data: JSON.stringify(data),
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {

	//                 console.log("Search Results: Ajax error ", err);
	//                 callback(err.response, null);
	//             }
	//         });
	//     },

	postReply: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/postReply/",
		//     type: 'POST',
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/postReply/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getInboxReplies: function (
		reviewDocId,
		replyStatus,
		profileId,
		locId,
		callback,
	) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getInboxReplies?reviewDocId=" + reviewDocId + "&replyStatus=" + replyStatus + "&profileId=" + profileId + "&locationId=" + locId + "&masterLocationFilter=" + localStorage.getItem('masterLocationFilter'),
		//     type: 'GET',
		//     contentType: "application/json",
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.get(
				config.server +
					"/getInboxReplies?reviewDocId=" +
					reviewDocId +
					"&replyStatus=" +
					replyStatus +
					"&profileId=" +
					profileId +
					"&locationId=" +
					locId +
					"&masterLocationFilter=" +
					localStorage.getItem("masterLocationFilter"),
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReplies: function (reviewDocId, replyStatus, profileId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getReplies?reviewDocId=" + reviewDocId + "&replyStatus=" + replyStatus + "&profileId=" + profileId,
		//     type: 'GET',
		//     contentType: "application/json",
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {

		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.get(
				config.server +
					"/getReplies?reviewDocId=" +
					reviewDocId +
					"&replyStatus=" +
					replyStatus +
					"&profileId=" +
					profileId,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	searchWorkspace: function (domainName, emailId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/requestWorkspaceUserAccess?domainName=" +
					domainName +
					"&workspaceOwnerId=" +
					emailId,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	createWorkSpace: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/createNewWorkspace", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	searchWorkspaceName: function (accountName, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/validateWorkspace?accountName=" + accountName, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getOrganisations: function (
		profileId,
		listAllAccounts,
		fromPage,
		size,
		callback,
	) {
		var TOKEN = cookie.readCookie("lctoken");
		var url =
			config.server +
			"/getOrganizations?profileId=" +
			profileId +
			"&listAllAccounts=" +
			listAllAccounts;
		if (fromPage !== undefined && size) {
			url += "&from=" + fromPage + "&size=" + size;
		}
		axios
			.get(url, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getSearchOrganisations: function (
		profileId,
		listAllAccounts,
		fromPage,
		size,
		serach,
		callback,
	) {
		var TOKEN = cookie.readCookie("lctoken");
		var url =
			config.server +
			"/getOrganizations?profileId=" +
			profileId +
			"&listAllAccounts=" +
			listAllAccounts +
			"&searchTerm=" +
			serach;
		if (fromPage !== undefined && size) {
			url += "&from=" + fromPage + "&size=" + size;
		}
		// $.ajax({
		//     url: url,
		//     type: 'GET',
		//     contentType: "application/json",
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {

		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.get(url, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	listGooglePlayAccounts: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/listGooglePlayAccounts", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	listAppStoreAccounts: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/listAppStoreAccounts", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	activateGooglePlayAccount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/activateGooglePlayAccount", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	activateAppStoreAccount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/activateAppStoreAccount", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	activateAppleAccounts: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/activateAppleAccounts", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	activateAppleLocation: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/activateAppleLocations", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editAppleAccount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/editAppleMapsAccount", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateAppleAccount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateAppleMapsAccountStatus", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteAppleLocation: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/deleteAppleMapsLocation", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteAppleAccount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/deleteAppleMapsAccount", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	listAppleMapsAccount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			// .post(config.server + '/getAppleMapsAccounts', data, {
			.post(config.server + "/getBusinessAccounts", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAppleLocations: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getAppleLocations", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAppleBusinessLocations: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getAppleBusinessConnectLocations", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	searchAppleMapsAccount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/searchAppleLocations", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getActivatedPlayAccounts: function (profileId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		var url =
			config.server + "/getActivatedPlayAccounts?profileId=" + profileId;
		// if (fromPage !== undefined && size) {
		//     url += "&from=" + fromPage + "&size=" + size;
		// }
		axios
			.get(url, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getUsersForProfile: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		//  var url = "/getUsersForProfile", data;
		// if (searchTerm) { url += "&searchTerm=" + searchTerm }
		// $.ajax({
		//     url: config.server + url,
		//     type: 'GET',
		//     contentType: "application/json",
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("getUsersForProfile: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getUsersForProfile", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	editRole: function (data, callback) {
		axios
			.post(config.server + "/editRole", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getTripadvisorReviewComments: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/uploadTripAdvisorPages",
		//     data: data,
		//     headers: {
		//         "Token": token
		//     },
		//     type: 'POST',
		//     cache: false,
		//     processData: false,
		//     contentType: false,
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results Tripadvisor: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/uploadTripAdvisorPages", data, {
				headers: {
					Token: token,
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReviewsCsv: function (data, callback) {
		axios
			.post(config.server + "/getReviewsCsv", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getOpenReviewsCsv: function (data, callback) {
		axios
			.post(config.server + "/getOpenReviewsCsv", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getYelpTsv: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/uploadYelpPages",
		//     data: data,
		//     headers: {
		//         "Token": token
		//     },
		//     type: 'POST',
		//     cache: false,
		//     processData: false,
		//     contentType: false,
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results Yelp: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/uploadYelpPages", data, {
				headers: {
					Token: token,
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	UploadCsvReports: function (data, callback) {
		// var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/uploadBulkRealTimeReport",
		//     data: data,
		//     headers: {
		//         "Token": token
		//     },
		//     type: 'POST',
		//     cache: false,
		//     processData: false,
		//     contentType: false,
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results UploadCsvReports ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/uploadBulkRealTimeReport", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getPhotoLocation: function (data, callback) {
		// var token = cookie.readCookie("superuser") || cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocationsPhotoUrls", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	createPlan: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/createPlan",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log(" Ajax error at createPlan ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/createPlan", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	addWorkspaceProfile: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		axios
			.post(config.server + "/addWorkspaceProfiles", data, {
				headers: {
					Token: TOKEN,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	addWorkspaceOwner: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		axios
			.post(config.server + "/addWorkspaceOwner", data, {
				headers: {
					Token: TOKEN,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteWorkSpaceProfile: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		axios
			.post(config.server + "/deleteProfileWorkspace", data, {
				headers: {
					Token: TOKEN,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getCustomerPhotoLocation: function (data, callback) {
		// var token = cookie.readCookie("superuser")||cookie.readCookie("lctoken");
		data["userId"] = cookie.readCookie("userId");
		axios
			.post(config.server + "/getLocationsCustomersPhotoUrls", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getFetcherHealthData: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/fetcherHealthData",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/fetcherHealthData", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationInsightHistogramCsv: function (data, callback) {
		axios
			.post(config.server + "/getLocationInsightHistogramCsv/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalActionConversionHistogram: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		var test = JSON.parse(JSON.stringify(data));

		// $.ajax({
		//     url: config.server + "/getLocalActionConversionHistogram/",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {

		//         console.log("getLocalActionConversionHistogram Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getLocalActionConversionHistogram/", test, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	hideAuthorisedAccount: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/hideAuthorisedAccount",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/hideAuthorisedAccount", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	hideFacebookAccount: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		//         $.ajax({
		//             url: config.server + "/hideFacebookAccount",
		//             type: 'POST',
		//             contentType: "application/json",
		//             dataType: 'JSON',
		//             headers: {
		//                 "Token": token,
		//                 "Content-Type": "application/json"
		//             },
		//             data: JSON.stringify(data),
		//             success: function (resp) {
		//                 callback(null, resp)
		//             },
		//             error: function (err) {
		//                 console.log("Search Results: Ajax error ", err);
		//                 callback(err.response, null)
		//             }
		//         });
		axios
			.post(config.server + "/hideFacebookAccount", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalActionHistogramCsv: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocalActionConversionHistogramCsv/",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {

		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getLocalActionConversionHistogramCsv/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationIdCsv: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocationIdCsv",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("getLocationIdCsv Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getLocationIdCsv/", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	downloadFoodMenu: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		axios
			.post(config.server + "/downloadFoodMenu/", data, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	downloadAllListing: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		axios
			.post(config.server + "/downloadAllListing/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationService: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		axios
			.post(config.server + "/downloadLocationServices/", data, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadMasterLocationCsv: function (data, callback) {
		axios
			.post(config.server + "/uploadMasterLocationCsv/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAssignedNotes: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getAssignedNotes",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getAssignedNotes", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllReviewTags: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getAllReviewTags", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllReviewTagsDownload: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getAllReviewTagsCsv", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllReviewData: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getAllReviewData",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("getAllReviewData Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getAllReviewData", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteINote: function (profileId, noteid, date, callback) {
		axios
			.get(
				config.server +
					"/deleteNote?profileId=" +
					profileId +
					"&noteId=" +
					noteid +
					"&date=" +
					date,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateNote: function (data, callback) {
		axios
			.post(config.server + "/updateNote", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	unassignNotes: function (profileId, reviewDocId, date, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/unassignNotes?profileId=" + profileId + "&reviewDocId=" + reviewDocId + "&date=" + date,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("unassignNotes Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/unassignNotes?profileId=" +
					profileId +
					"&reviewDocId=" +
					reviewDocId +
					"&date=" +
					date,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadCitationData: function (data, callback) {
		axios
			.post(config.server + "/uploadCitationData", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getCitationData: function (profileId, type, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getCitationData?profileId=" + profileId + "&indexType=" + type,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getCitationData?profileId=" +
					profileId +
					"&indexType=" +
					type,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	createLocalPost: function (data, callback) {
		axios
			.post(config.server + "/createLocalPost", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getMasterDataStatus: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getMasterDataStatus?profileId=" + profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search getMasterDataStatus: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/getMasterDataStatus?profileId=" + profileId, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalPosts: function (profileId, locationId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocalPosts?profileId=" + profileId + "&locationId=" + locationId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search getLocalPosts: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getLocalPosts?profileId=" +
					profileId +
					"&locationId=" +
					locationId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/x-www-form-urlencoded",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteLocalPost: function (data, callback) {
		axios
			.delete(config.server + "/deleteLocalPost", {
				headers: generateHeaderWithToken(),
				data,
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadPostImages: function (data, callback) {
		axios
			.post(config.server + "/uploadPostImages", data, {
				headers: generateHeaderWithToken(),
				cache: false,
				processData: false,
				contentType: false,
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getScheduleLocalPost: function (profileId, locationId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getScheduledPosts?profileId=" + profileId + "&locationId=" + locationId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search getLocalPosts: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getScheduledPosts?profileId=" +
					profileId +
					"&locationId=" +
					locationId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/x-www-form-urlencoded",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateScheduledPost: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		//         $.ajax({
		//             url: config.server + "/updateScheduledPost",
		//             type: 'POST',
		//             contentType: "application/json",
		//             dataType: 'JSON',
		//             headers: {
		//                 "Token": token,
		//                 "Content-Type": "application/json"
		//             },
		//             data: JSON.stringify(data),
		//             success: function (resp) {
		//                 callback(null, resp)
		//             },
		//             error: function (err) {
		//                 console.log("Search updateScheduledPost: Ajax error ", err);
		//                 callback(err.response, null);
		//             }
		//         });
		axios
			.post(config.server + "/updateScheduledPost", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateLocalPost: function (data, callback) {
		axios
			.post(config.server + "/updateLocalPost", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteScheduledPost: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/deleteScheduledPost",
		//     type: 'DELETE',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search deleteScheduledPost: Ajax error ", err);
		//         callback(err.response, null);
		//     }
		// });
		axios
			.delete(config.server + "/deleteScheduledPost", {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
				data: data,
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadlocalpost: function (data, callback) {
		axios
			.post(config.server + "/upload/localPost", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalPostHistory: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocalPostHistory?profileId=" + profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("getLocalPostHistory: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getLocalPostHistory", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getPostHistory: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getPostHistory", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getMasterLocationDetails: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getMasterLocationDetails",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("getMasterLocationDetails: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getMasterLocationDetails", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	//     getLawyersPages: function (profileId, fromPage, size, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getLawyersPages?profileId=" + profileId + "&from=" + fromPage + "&size=" + size,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 console.log("Search Results: Ajax error getLawyersPages ", err);
	//                 callback(err.response, null)
	//             }
	//         });
	//     },
	getSources: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		//         $.ajax({
		//             url: config.server + "/getSources?profileId=" + profileId,
		//             type: 'GET',
		//             headers: {
		//                 "Token": token,
		//                 "Content-Type": "application/json"
		//             },
		//             contentType: "application/json",
		//             success: function (resp) {
		//                 callback(null, resp)
		//             },
		//             error: function (err) {
		//                 callback(err.response, null)
		//             }
		//         });
		axios
			.get(config.server + "/getSources?profileId=" + profileId, {
				headers: {
					Token: token,
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getSourcesLocations: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/getSources?profileId=" +
					profileId +
					"&locationSources=true",
				{
					headers: {
						Token: token,
						"Content-Type": "application/x-www-form-urlencoded",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getSearchSources: function (profileId, search, callback) {
		var token = cookie.readCookie("lctoken");
		//         $.ajax({
		//             url: config.server + "/getSources?profileId=" + profileId,
		//             type: 'GET',
		//             headers: {
		//                 "Token": token,
		//                 "Content-Type": "application/json"
		//             },
		//             contentType: "application/json",
		//             success: function (resp) {
		//                 callback(null, resp)
		//             },
		//             error: function (err) {
		//                 callback(err.response, null)
		//             }
		//         });
		axios
			.get(
				config.server +
					"/getSources?profileId=" +
					profileId +
					"&searchTerm=" +
					search,
				{
					headers: {
						Token: token,
						"Content-Type": "application/x-www-form-urlencoded",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	//     getRatemdsPages: function (profileId, fromPage, size, callback) {
	//         var token = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getRatemdsPages?profileId=" + profileId + "&from=" + fromPage + "&size=" + size,
	//             type: 'GET',
	//             headers: {
	//                 "Token": token,
	//                 "Content-Type": "application/json"
	//             },
	//             contentType: "application/json",
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 callback(err.response, null)
	//             }
	//         });
	//     },
	getAttributeList: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getAttributeList",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("getAttributeList: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getAttributeList", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getPupprenderPages: function (profileId, fromPage, size, type, callback) {
		var token = cookie.readCookie("lctoken");
		//         $.ajax({
		//             url: config.server + "/getPupprenderPages?profileId=" + profileId + "&from=" + fromPage + "&size=" + size + "&reviewType=" + type,
		//             type: 'GET',
		//             headers: {
		//                 "Token": token,
		//                 "Content-Type": "application/json"
		//             },
		//             contentType: "application/json",
		//             success: function (resp) {
		//                 callback(null, resp)
		//             },
		//             error: function (err) {
		//                 callback(err.response, null)
		//             }
		//         });
		axios
			.get(
				config.server +
					"/getPupprenderPages?profileId=" +
					profileId +
					"&from=" +
					fromPage +
					"&size=" +
					size +
					"&reviewType=" +
					type,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	bulkUploadLocationFilterTag: function (data, callback) {
		axios
			.post(config.server + "/bulkUploadLocationFilterTag", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationDetailsCsv: function (data, callback) {
		axios
			.post(config.server + "/getLocationDetailsCsv", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAppleMapsData: function (data, callback) {
		axios
			.post(config.server + "/getAppleMapsData", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllAppleLocations: function (data, callback) {
		axios
			.post(config.server + "/getAllAppleLocations", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllAppleLocations: function (data, callback) {
		axios
			.post(config.server + "/getAllAppleLocations", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAppleAttributes: function (data, callback) {
		axios
			.post(config.server + "/getAppleAttributes", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAppleCategories: function (data, callback) {
		axios
			.post(config.server + "/getAppleCategories", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllLocations: function (data, callback) {
		axios
		  .post(config.server + '/getAllLocations', data, {
			headers: generateHeaderWithToken(),
		  })
		  .then((resp) => {
			callback(null, resp.data);
		  })
		  .catch((err) => {
			callback(err.response, null);
		  });
	  },
	viewFilterTag: function (
		filterTagId,
		groupType,
		profileId,
		masterLocationFilter,
		callback,
	) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/viewFilterTag?filterTagId=" + filterTagId + "&profileId=" + profileId + "&masterLocationFilter=" + masterLocationFilter,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/viewFilterTag?filterTagId=" +
					filterTagId +
					"&groupType=" +
					groupType +
					"&profileId=" +
					profileId +
					"&masterLocationFilter=" +
					masterLocationFilter,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editLocationFilterTag: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/editLocationFilterTag",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/editLocationFilterTag", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadLogoImages: function (data, callback) {
		axios
			.post(config.server + "/uploadLogoImages", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getPlanDetails: function (callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		// $.ajax({
		//     url: config.server + "/getPlanDetails",
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "AdminToken": adminToken,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/getPlanDetails", {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deletePlan: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		// $.ajax({
		//     url: config.server + "/deletePlan?profileId=" + profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "AdminToken": adminToken,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/deletePlan?profileId=" + profileId, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editPlanDetails: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		// $.ajax({
		//     url: config.server + "/editPlanDetails",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "AdminToken": adminToken,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/editPlanDetails", data, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getProfilesAdmin: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		axios
			.post(config.server + "/getProfilesAdmin", data, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getWorkspaceTable: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		axios
			.post(config.server + "/getWokspacesAdmin", data, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editProfileName: function (data, callback) {
		axios
			.post(config.server + "/editProfileName", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateWorkSpace: function (data, callback) {
		axios
			.post(config.server + "/editWorkspace", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getDomainName: function (callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getAllDomainName",
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/getAllDomainName", {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	refreshUsageInfo: function (callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/refreshUsageInfo",
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(config.server + "/refreshUsageInfo", {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	refreshProfileLocation: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/refreshProfileSummary", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	fetchLocationVerificationOptions: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/fetchLocationVerificationOptions",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/fetchLocationVerificationOptions", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationVerify: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocationVerify",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getLocationVerify", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationsCount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocationsCount", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	completeLocationVerification: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/completeLocationVerification",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/completeLocationVerification", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	sendExternalNote: function (data, callback) {
		axios
			.post(config.server + "/createExternalNote", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteExternalNote: function (noteId, date, profileId, callback) {
		axios
			.get(
				config.server +
					"/deleteExternalNote?profileId=" +
					profileId +
					"&date=" +
					date +
					"&noteId=" +
					noteId,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	createLeftNavigationLink: function (data, callback) {
		axios
			.post(config.server + "/createLeftNavigationLink", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLeftNavigationLinks: function (profileId, domainName, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLeftNavigationLinks?profileId=" + profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getLeftNavigationLinks?profileId=" +
					profileId +
					"&domainName=" +
					domainName,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteLeftNavigationLinks: function (profileId, tabId, callback) {
		axios
			.get(
				config.server +
					"/deleteLeftNavigationLink?profileId=" +
					profileId +
					"&tabId=" +
					tabId,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteCustomNavigationLinks: function (domainName, tabId, callback) {
		axios
			.get(
				config.server +
					"/deleteCustomDomainNavigation?domainName=" +
					domainName +
					"&tabId=" +
					tabId,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editLeftNavigationLink: function (data, callback) {
		axios
			.post(config.server + "/editLeftNavigationLink", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getEntityTags: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getEntityTags",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getEntityTags", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	addLocationContactDetails: function (data, callback) {
		axios
			.post(config.server + "/manipulateLocationContactDetails", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteLocationNote: function (profileId, locationId, callback) {
		axios
			.get(
				config.server +
					"/deleteLocationNote?profileId=" +
					profileId +
					"&locationId=" +
					locationId +
					"&masterLocationFilter=" +
					localStorage.getItem("masterLocationFilter"),
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getQuestionAnswers: function (QuestionDocId, profileId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getQuestionAnswers?questionDocId=" + QuestionDocId + "&profileId=" + profileId,
		//     type: 'GET',
		//     contentType: "application/json",
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null);
		//     }
		// });
		axios
			.get(
				config.server +
					"/getQuestionAnswers?questionDocId=" +
					QuestionDocId +
					"&profileId=" +
					profileId,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	sendAnswerToQuestion: function (data, callback) {
		axios
			.post(config.server + "/sendAnswerToQuestion", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	refreshLocation: function (data, callback) {
		axios
			.get(
				config.server +
					"/refreshLocation?locationId=" +
					data.locationId +
					"&accountId=" +
					data.accountId +
					"&profileId=" +
					data.profileId,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	bulkUploadLocationNotes: function (data, callback) {
		axios
			.post(config.server + "/bulkUploadLocationNotes", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationNotesUploadCsv: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocationNotesUploadCsv",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/getLocationNotesUploadCsv", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	bulkUploadLocationImageLinks: function (data, callback) {
		axios
			.post(config.server + "/bulkUploadLocationImageLinks", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	hideLocations: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/hideLocations",
		//     type: 'POST',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.post(config.server + "/hideLocations", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	//     getpublicimage: function (data, callback) {
	//         var TOKEN = cookie.readCookie("lctoken");
	//         $.ajax({
	//             url: config.server + "/getLocationPublicImageHistogram/",
	//             type: 'POST',
	//             contentType: "application/json",
	//             dataType: 'JSON',
	//             headers: {
	//                 "Token": TOKEN,
	//                 "Content-Type": "application/json"
	//             },
	//             data: JSON.stringify(data),
	//             success: function (resp) {
	//                 callback(null, resp)
	//             },
	//             error: function (err) {
	//                 callback(err.response, null);
	//             }
	//         });
	//     },
	setReplyContactMail: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/setReplyContactMail",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/setReplyContactMail", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationDetails: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocationDetails?locationId=" + data.locationId + "&profileId=" + data.profileId + "&masterLocationFilter=" + data.masterLocationFilter,
		//     type: 'GET',
		//     contentType: "application/json",
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null);
		//     }
		// });
		axios
			.get(
				config.server +
					"/getLocationDetails?locationId=" +
					data.locationId +
					"&profileId=" +
					data.profileId +
					"&masterLocationFilter=" +
					data.masterLocationFilter,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	googleLocationsSearch: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/googleLocationsSearch",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/googleLocationsSearch", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	createLocation: function (data, callback) {
		axios
			.post(config.server + "/createLocation", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadGoogleLocationImageLinks: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/uploadGoogleLocationImageLinks",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/uploadGoogleLocationImageLinks", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	createLocationQuestion: function (data, callback) {
		axios
			.post(config.server + "/createLocationQuestion", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalPostUploadCsv: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocalPostUploadCsv",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null);
		//     }
		// });
		axios
			.post(config.server + "/getLocalPostUploadCsv", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	bookingAuth: function (profileId, urlName, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/bookingAuth?profileId=" + profileId + '&domainName=' + urlName,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/bookingAuth?profileId=" +
					profileId +
					"&domainName=" +
					urlName,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getIntegrationLocations: function (
		profileId,
		fromPage,
		size,
		type,
		callback,
	) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getIntegrationLocations?profileId=" + profileId + "&from=" + fromPage + "&size=" + size + "&reviewType=" + type,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getIntegrationLocations?profileId=" +
					profileId +
					"&from=" +
					fromPage +
					"&size=" +
					size +
					"&reviewType=" +
					type,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	zomatovalidation: function (id, callback) {
		// $.ajax({
		//     url: "https://developers.zomato.com/api/v2.1/restaurant?res_id=" + id,
		//     type: 'GET',
		//     headers: {
		//         "user-key": "168df782864ab5de16cfdb1bc97a71c2",
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get("https://developers.zomato.com/api/v2.1/restaurant?res_id=" + id, {
				headers: {
					"user-key": "168df782864ab5de16cfdb1bc97a71c2",
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationFilterTagCsv: function (
		profileId,
		masterLocation,
		domain,
		callback,
	) {
		axios
			.get(
				config.server +
					"/getLocationFilterTagCsv?profileId=" +
					profileId +
					"&masterLocationFilter=" +
					masterLocation +
					"&domainName=" +
					domain,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getBookingLocations: function (profileId, fromPage, size, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getBookingLocations?profileId=" + profileId + "&from=" + fromPage + "&size=" + size,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getBookingLocations?profileId=" +
					profileId +
					"&from=" +
					fromPage +
					"&size=" +
					size,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	enableBookingLocation: function (profileId, hotelId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/enableBookingLocation?profileId=" + profileId + "&hotelId=" + hotelId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/enableBookingLocation?profileId=" +
					profileId +
					"&hotelId=" +
					hotelId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	disableBookingLocation: function (profileId, hotelId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/disableBookingLocation?profileId=" + profileId + "&hotelId=" + hotelId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err.response, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/disableBookingLocation?profileId=" +
					profileId +
					"&hotelId=" +
					hotelId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationAudits: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocationAudits",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err, null);
		//     }
		// });
		axios
			.post(config.server + "/getLocationAudits", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getProfileAudits: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getProfileAudits",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err, null);
		//     }
		// });
		axios
			.post(config.server + "/getProfileAudits", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	scheduleAutomaticWeeklySync: function (data, callback) {
		axios
			.post(config.server + "/scheduleAutomaticWeeklySync", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAutomaticWeeklySyncData: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getAutomaticWeeklySyncData?profileId=" + profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err, null)
		//     }
		// });
		axios
			.get(
				config.server + "/getAutomaticWeeklySyncData?profileId=" + profileId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getWebhosePages: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getWebhosePages",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err, null);
		//     }
		// });
		axios
			.post(config.server + "/getWebhosePages", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalPostInsightHistogram: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocalPostInsightHistogram/",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err, null);
		//     }
		// });
		axios
			.post(config.server + "/getLocalPostInsightHistogram/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalPostAndInsightsCsv: function (data, callback) {
		axios
			.post(config.server + "/getLocalPostAndInsightsCsv/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalPostInsightCount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocalPostInsightCount/",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err, null);
		//     }
		// });
		axios
			.post(config.server + "/getLocalPostInsightCount/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	removeMasterLocation: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/removeMasterLocation?profileId=" + profileId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err, null)
		//     }
		// });
		axios
			.get(config.server + "/removeMasterLocation?profileId=" + profileId, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalPostAndInsightDetails: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocalPostAndInsightDetails",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err, null);
		//     }
		// });
		axios
			.post(config.server + "/getLocalPostAndInsightDetails", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalPostAnalysis: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getLocalPostAnalysis",
		//     type: 'POST',
		//     contentType: "application/json",
		//     dataType: 'JSON',
		//     headers: {
		//         "Token": TOKEN,
		//         "Content-Type": "application/json"
		//     },
		//     data: JSON.stringify(data),
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err, null);
		//     }
		// });
		axios
			.post(config.server + "/getLocalPostAnalysis", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAuditsCsv: function (data, callback) {
		axios
			.post(config.server + "/getAuditsCsv", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteAudits: function (profileId, auditId, callback) {
		axios
			.get(
				config.server +
					"/deleteAudits?profileId=" +
					profileId +
					"&auditId=" +
					auditId,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	trustPilotAuth: function (profileId, urlName, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/trustPilotAuth?profileId=" + profileId + '&domainName=' + urlName,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/trustPilotAuth?profileId=" +
					profileId +
					"&domainName=" +
					urlName,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	refreshTrustPilotAuth: function (profileId, businessId, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/refreshTrustpilotBusinessUnit?profileId=" +
					profileId +
					"&businessUnitId=" +
					businessId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getTrustPilotLocations: function (profileId, fromPage, size, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/getTrustPilotLocations?profileId=" + profileId + "&from=" + fromPage + "&size=" + size,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/getTrustPilotLocations?profileId=" +
					profileId +
					"&from=" +
					fromPage +
					"&size=" +
					size,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	enableTrustPilotLocation: function (profileId, businessId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/enableTrustPilotLocation?profileId=" + profileId + "&businessUnitId=" + businessId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         callback(err, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/enableTrustPilotLocation?profileId=" +
					profileId +
					"&businessUnitId=" +
					businessId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	disableTrustPilotLocation: function (profileId, businessId, callback) {
		var token = cookie.readCookie("lctoken");
		// $.ajax({
		//     url: config.server + "/disableTrustPilotLocation?profileId=" + profileId + "&businessUnitId=" + businessId,
		//     type: 'GET',
		//     headers: {
		//         "Token": token,
		//         "Content-Type": "application/json"
		//     },
		//     contentType: "application/json",
		//     success: function (resp) {
		//         callback(null, resp)
		//     },
		//     error: function (err) {
		//         console.log("Search Results: Ajax error ", err);
		//         callback(err, null)
		//     }
		// });
		axios
			.get(
				config.server +
					"/disableTrustPilotLocation?profileId=" +
					profileId +
					"&businessUnitId=" +
					businessId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalPostAndInsightDetailsCsv: function (data, callback) {
		axios
			.post(config.server + "/getLocalPostAndInsightDetailsCsv", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	addReplyNames: function (data, callback) {
		axios
			.post(config.server + "/addLocationAlias", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReplyNamesUploadCsv: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getReplyNamesUploadCsv", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	bulkUploadLocationAliases: function (data, callback) {
		axios
			.post(config.server + "/bulkUploadLocationAliases", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateLocalPostStatus: function (profileId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/updateLocalPostStatus?profileId=" + profileId, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteReplyNames: function (data, callback) {
		axios
			.post(config.server + "/deleteLocationAlias", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalPostCount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocalPostCount", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	addReviewStarReply: function (data, callback) {
		axios
			.post(config.server + "/addARTemplate", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	bulkUploadReviewStarReplies: function (data, callback) {
		axios
			.post(config.server + "/bulkUploadARTemplates", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteReviewStarReply: function (profileId, id, callback) {
		axios
			.get(
				config.server +
					"/deleteARTemplate?profileId=" +
					profileId +
					"&id=" +
					id,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReviewStarReplies: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		// axios.post(config.server + "/getAllARTemplates?profileId=" + data.profileId + "&from=" + data.from + "&size=" + data.size + "&searchText=" + data.searchText,
		axios
			.post(config.server + "/getAllARTemplates", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editReviewStarReply: function (data, callback) {
		axios
			.post(config.server + "/editARTemplate", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalPostStatusUpdateTime: function (profileId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(
				config.server + "/getLocalPostStatusUpdateTime?profileId=" + profileId,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReplyNamesUploadCsv: function (data, callback) {
		axios
			.post(config.server + "/getLocationAliasesUploadCsv", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadGoogleQA: function (data, callback) {
		axios
			.post(config.server + "/uploadGoogleQA", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getGoogleQAUploadCsv: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getGoogleQAUploadCsv", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getFetchQueueStatus: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/getFetchQueueStatus" +
					"?profileId=" +
					data.profileId +
					"&fetchType=" +
					data.fetchType,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getGoogleQAHistory: function (profileId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/getGoogleQAHistory" + "?profileId=" + profileId, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	addARSettings: function (data, callback) {
		axios
			.post(config.server + "/addARSettings", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	addNewAIAgent: function (data, callback) {
		axios
			.post(config.server + "/addNewAIAgent", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateAgent: function (data, callback) {
		axios
			.post(config.server + "/editAIAgent", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllARSettings: function (profileId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/getAllARSettings" + "?profileId=" + profileId, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllAIAgents: function (data, callback) {
		axios
			.post(config.server + "/getAllAIAgents", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteAIAgent: function (data, callback) {
		axios
			.post(config.server + "/deleteAIAgent", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteARSettings: function (profileId, id, callback) {
		axios
			.get(
				config.server +
					"/deleteARSettings" +
					"?profileId=" +
					profileId +
					"&id=" +
					id,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editARSettings: function (data, callback) {
		axios
			.post(config.server + "/editARSettings", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	validateAIAgent: function (data, callback) {
		axios
			.post(config.server + "/validateAIAgent", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	validateARSettings: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/validateARSettings", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	integrationsadd: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/integrations/add", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	addNav: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/createCustomDomainNavigation", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	integrationMonitor: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getAllIntegratedIndexResourcesReport", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllWorkspaces: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getAllProfilesOrWorkspaces", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getSourceList: function (callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/getAllIndexedSources", {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	integrationsread: function (domain, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/integrations/read?domain=" + domain, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getWorkplace: function (callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/getWorkspaces", {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getWorkplaceAccount: function (accountId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/getWorkspaces?accountId=" + accountId, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getWorkplaceUpdateTime: function (callback) {
		var TOKEN = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		let accountId = cookie.readCookie("currentAccountId");
		axios
			.get(config.server + "/updateWorkspaceLoginTime?accountId=" + accountId, {
				headers: {
					Token: TOKEN,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllWorkspaceProfile: function (accountId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/getAllWorkspaceProfiles?accountId=" + accountId, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getWorkplaceUsers: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getAllWorkspaceUsers", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteUserFromProfile: function (data, callback) {
		let accountId = cookie.readCookie("currentAccountId");
		if (accountId && accountId != null) {
			data.accountId = accountId;
		}
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/deleteUserFromProfile", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	manageWorkspaceRequest: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/manageUsersWorkspaceRequest", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getPaymentMethod: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/getWorkspaceCardDetails?accountId=" + data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getprofilesWorkspace: function (accountId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getProfilesByWorkspace", accountId, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getPaymentHistory: function (accountId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/getWorkspacePaymentHistory?accountId=" +
					accountId +
					"&limit=10",
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getPaymentHost: function (profileId, type, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/getHostPagePayment?profileId=" +
					profileId +
					"&type=" +
					type,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	paymentDelete: function (profileId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/deleteCard?profileId=" + profileId, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getCustomNav: function (domain, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/getCustomDomainNavigations?domainName=" + domain, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	integrationsupdate: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/integrations/update", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateCustomNav: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/editCustomDomainNavigation", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	integrationsdelete: function (domain, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.delete(config.server + "/integrations/delete?domain =" + domain, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getCategoryListCsv: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getCategoryListCsv", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	requestDateRangeReviewClarity: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/requestDateRangeReviewClarity", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReviewResponseHistogram: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getReviewResponseHistogram", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReviewClarityHistory: function (profileId, from, size, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/getReviewClarityHistory" +
					"?profileId=" +
					profileId +
					"&from=" +
					from +
					"&size=" +
					size,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	ReviewClarityHistoryById: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getReviewClarityHistory", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReviewClarityReviews: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getReviewClarityReviews", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getIntegrationConnections: function (profileId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/getIntegrationConnections" +
					"?profileId=" +
					profileId,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	downloadReportConfiguration: function (profileId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/downloadReportConfiguration" +
					"?profileId=" +
					profileId,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadFirstPartyReviewsCsv: function (data, callback) {
		axios
			.post(config.server + "/uploadFirstPartyReviewsCsv", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadReviewShakeUrls: function (data, callback) {
		axios
			.post(config.server + "/uploadReviewShakeUrls", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReviewShakePages: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getReviewShakePages", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteReviewShakeUrls: function (data, callback) {
		axios
			.post(config.server + "/deleteReviewShakeUrl", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editSentReply: function (data, callback) {
		axios
			.post(config.server + "/editSentReply", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	verifyUserEmail: function (data, callback) {
		var TOKEN = cookie.readCookie("superuser");
		axios
			.post(config.server + "/verifyUserEmail", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteProfileUser: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/deleteProfileUsers", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	markReviewsDeleted: function (data, callback) {
		axios
			.post(config.server + "/markReviewsDeleted", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadReviewShakeAppUrls: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/uploadReviewShakeAppUrls", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadReviewShakeAppFileData: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/uploadReviewShakeAppFileData", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadLocalPostToSchedule: function (data, callback) {
		axios
			.post(config.server + "/uploadLocalPostToSchedule", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getScheduledLocalPostStatus: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getScheduledLocalPostStatus", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalPostVerificationResults: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocalPostVerificationResults", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalPostVerificationCounts: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocalPostVerificationCounts", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllScheduledLocalPostUploads: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getAllScheduledLocalPostUploads", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editValidatedLocalPost: function (data, callback) {
		axios
			.post(config.server + "/editValidatedLocalPost", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteValidatedLocalPost: function (data, callback) {
		axios
			.post(config.server + "/deleteValidatedLocalPost", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	scheduleVerifiedLocalPosts: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/scheduleVerifiedLocalPosts", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getScheduledLocalPosts: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getScheduledLocalPosts", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalPostSchedulingStatus: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocalPostSchedulingStatus", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getGoogleSheetCreateStatus: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getGoogleSheetCreateStatus", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationDetailsInGoogleSheets: function (data, callback) {
		axios
			.post(config.server + "/getLocationDetailsInGoogleSheets", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	bulkUpdateLocationDetails: function (data, callback) {
		axios
			.post(config.server + "/bulkUpdateLocationDetails", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	unhideFacebookAccount: function (data, callback) {
		axios
			.post(config.server + "/unhideFacebookAccount", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadWhitelabelImage: function (data, callback) {
		axios
			.post(config.server + "/uploadWhitelabelImage", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllGmbEditRequests: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getAllLocationEditRequests", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getGmbEditResult: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocationEditResult", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getCommentsCount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getCommentsCount", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getWordCount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getWordCount", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadCompetitorUrls: function (data, callback) {
		axios
			.post(config.server + "/uploadCompetitorUrls", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getTopCompetitors: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getTopCompetitors", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	closeReview: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateClosedReview", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	reopenClosedReview: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/reopenReview", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReviewShakeReverseUrlLookUp: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getReviewShakeReverseUrlLookUp", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	addCompetitorNames: function (data, callback) {
		axios
			.post(config.server + "/addCompetitorNames", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getCompetitorNames: function (profileId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/getCompetitorNames?profileId=" + profileId, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadCompetitorImage: function (data, callback) {
		axios
			.post(config.server + "/uploadCompetitorImage", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editCompetitor: function (data, callback) {
		axios
			.post(config.server + "/editCompetitor", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteCompetitor: function (data, callback) {
		axios
			.post(config.server + "/deleteCompetitor", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getTopCompetitorsReplyRatingCount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getTopCompetitorsReplyRatingCount", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getTopCompetitorsCommentsCount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getTopCompetitorsCommentsCount", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getTopCompetitorsWordsCount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getTopCompetitorsWordCount", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	unMarkReviewsDeleted: function (data, callback) {
		axios
			.post(config.server + "/unMarkReviewsDeleted", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	reactiveSubscription: function (profileId, callback) {
		axios
			.get(config.server + "/reactiveSubscription?profileId=" + profileId, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getCompetitorReviews: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getCompetitorReviews", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getCompetitorReviewRating: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getCompetitorReviewRating", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getCompetitorSources: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/getSources?profileId=" +
					profileId +
					"&isCompetitor=" +
					true,
				{
					headers: {
						Token: token,
						"Content-Type": "application/x-www-form-urlencoded",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getCompetitorLocations: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getCompetitorLocations", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateCompetitorTags: function (data, callback) {
		axios
			.post(config.server + "/updateCompetitorTags", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	resyncReviewShakeAccount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/resyncReviewShakeAccount", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getMailedReportDetails: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getMailedReportDetails", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteBulkManagementHistory: function (data, callback) {
		axios
			.post(config.server + "/deleteBulkManagementHistory", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadUserImage: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/uploadUserImage", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getUser: function (userId, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		let data = { userId: userId };
		axios
			.post(config.server + "/getUser", data, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateProfileLandingPage: function (tabName, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/updateProfileLandingPage?landingPage=" + tabName, {
				headers: {
					Token: token,
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getUserDetailsCsv: function (profileId, domain, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/getUserDetailsCsv?profileId=" +
					profileId +
					"&domainName=" +
					domain,
				{
					headers: {
						Token: token,
						"Content-Type": "application/x-www-form-urlencoded",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getUserSettings: function (domainName, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/getUserSettings?domainName=" + domainName, {
				headers: {
					Token: token,
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getGoogleLocationDetailsCsv: function (profileId, domain, type, callback) {
		axios
			.get(
				config.server +
					"/getGoogleLocationDetailsCsv?profileId=" +
					profileId +
					"&domainName=" +
					domain +
					"&isCompetitor=" +
					type,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationReviewSummary: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocationReviewSummary", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateProfileUserFilter: function (data, callback) {
		axios
			.post(config.server + "/updateProfileUserFilter", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getCompetitorReviewsCsv: function (data, callback) {
		axios
			.post(config.server + "/getCompetitorReviewsCsv", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getReviewResponse: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getReviewResponse", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllReplyTemplates: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getAllReplyTemplates", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadCompetitorFileData: function (data, callback) {
		axios
			.post(config.server + "/uploadCompetitorFileData", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	createSnippet: function (data, callback) {
		axios
			.post(config.server + "/createSnippet", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateSnippet: function (data, callback) {
		axios
			.post(config.server + "/updateSnippet", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteSnippet: function (data, callback) {
		axios
			.post(config.server + "/deleteSnippet", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getDataStudioConnector: function (profileId, type, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/getDataStudioConnector?profileId=" +
					profileId +
					"&dataset=" +
					type,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getScrapperData: function (data, callback) {
		axios
			.post(config.server + "/getScraperStatus", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	fetchAuditData  : function (data, callback) {
		axios
			.post(config.server + "/fetchAuditData", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	auditDataFetchStatus: function (data, callback) {
		axios
			.post(config.server + "/auditDataFetchStatus", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
		},
	updateScrapperData: function (data, callback) {
		axios
			.post(config.server + "/scrapWebsiteData", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editBackOfficeId: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/editBackOfficeId", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllActiveLocationCount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getAllActiveLocationCount", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getUsageInfoDynamicSearch: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");

		axios
			.post(config.server + "/getUsageInfoDynamicSearch", data, {
				headers: {
					Token: TOKEN,
					Admintoken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getBillingLocationsCsv: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getBillingLocationsCsv", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	connectDataStudio: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/connectDataStudio", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getDataStudioStatus: function (field, value, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/getDataStudioStatus?" + field + "=" + value, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	createTier: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/createTier", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getTiers: function (field, value, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/getTiers?" + field + "=" + value, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteTier: function (id, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/deleteTier?tierId=" + id, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	assignTier: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/assignTier", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationStatusCount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocationStatusCount", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationGroups: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocationGroups", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationAttributesCsv: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocationAttributesCsv", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getConversionRates: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getConversionRates", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getConversionRatesCSV: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getConversionRatesCSV", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	blackListUsers: function (userId, isBlackListed, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/blackListUsers?userId=" +
					userId +
					"&isBlackListed=" +
					isBlackListed,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteUserAccount: function (userId, domainName, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/deleteUserAccount?userId=" +
					userId +
					"&domainName=" +
					domainName,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	createPortfolio: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/createPortfolio/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getPortfolios: function (callback) {
		var token = cookie.readCookie("lctoken");

		axios
			.get(config.server + "/getPortfolios", {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	deletePortfolio: function (portfolioId, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.delete(config.server + "/deletePortfolio?portfolioId=" + portfolioId, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getPortfolioUsers: function (portfolioId, fromPage, size, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		var url =
			"/getPortfolioUsers?portfolioId=" +
			portfolioId +
			"&from=" +
			fromPage +
			"&size=" +
			size;

		axios
			.get(config.server + url, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	blackListUsers: function (userId, isBlackListed, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/blackListUsers?userId=" +
					userId +
					"&isBlackListed=" +
					isBlackListed,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getPortfolioProfiles: function (portfolioId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		var url = "/getPortfolioProfiles?portfolioId=" + portfolioId;

		axios
			.get(config.server + url, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	assignProfilesToPortfolio: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/assignProfilesToPortfolio", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deletePortfolioProfile: function (profileId, portfolioId, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/deletePortfolioProfile?portfolioId=" +
					portfolioId +
					"&profileId=" +
					profileId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllUserCreatedRoles: function (profileId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		var url = "/getAllUserCreatedRoles?profileId=" + profileId;

		axios
			.get(config.server + url, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	invitePortfolioUser: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/invitePortfolioUser", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	removePortfolioUser: function (portfolioId, userId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		var url =
			"/removePortfolioUser?portfolioId=" + portfolioId + "&userId=" + userId;

		axios
			.get(config.server + url, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteUserAccount: function (userId, domainName, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/deleteUserAccount?userId=" +
					userId +
					"&domainName=" +
					domainName,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getPortfolioUserDetails: function (userId, portfolioId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/getPortfolioUserDetails?userId=" +
					userId +
					"&portfolioId=" +
					portfolioId,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	assignUsersToProfiles: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/assignUsersToProfiles", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editPortfolio: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/editPortfolio", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	uploadPortfolioImage: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/uploadPortfolioImage", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	saveUserSettings: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/saveUserSettings", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateProfileLoginTime: function (data, callback) {
		axios
			.get(config.server + "/updateProfileLoginTime?profileId=" + data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getPortfolioProfileList: function (data, callback) {
		// var TOKEN = cookie.readCookie("lctoken");
		// axios.get(config.server + "/getPortfolioProfileList", data,
		//     {
		//         headers: {
		//             "Token": TOKEN,
		//             "Content-Type": "application/json"
		//         }
		//     })
		//     .then((resp) => {
		//         callback(null, resp.data)
		//     })
		//     .catch((err) => {
		//         callback(err.response, null)
		//     })
		var token = cookie.readCookie("lctoken");
		if (data) {
			token = data;
		}

		axios
			.get(config.server + "/getPortfolioProfileList", {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	updatePaymentStatus: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");

		axios
			.post(config.server + "/updatePaymentStatus", data, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllScrapingRequests: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		axios
			.post(config.server + "/getAllScrapingRequests", data, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAlladminReports: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		axios
			.post(config.server + "/getAllAdminsReport", data, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getScrapingEditResult: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		var adminToken = cookie.readCookie("superuser");
		axios
			.post(config.server + "/getScrapingEditResult", data, {
				headers: {
					Token: token,
					AdminToken: adminToken,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getEditNotifications: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");

		axios
			.post(config.server + "/getEditNotifications", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getLocationEdits: function (data, callback) {
		var token = cookie.readCookie("lctoken");

		axios
			.post(config.server + "/getLocationEdits", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateNotification: function (data, callback) {
		var token = cookie.readCookie("lctoken");

		axios
			.post(config.server + "/updateNotification", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateLocationEdit: function (data, callback) {
		var token = cookie.readCookie("lctoken");

		axios
			.post(config.server + "/updateLocationEdit", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editAccount: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/editAccount", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationEditHistoryCsv: function (data, callback) {
		axios
			.post(config.server + "/getLocationEditHistoryCsv", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationImages: function (profileId, locationId, callback) {
		axios
			.get(
				config.server +
					"/getLocationImages?profileId=" +
					profileId +
					"&locationId=" +
					locationId,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getGoogleAccounts: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getGoogleAccounts", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	saveAuthorizedAccounts: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/saveAuthorizedAccounts", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getGoogleLocations: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getGoogleLocations", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getInitialFetchStatus: function (profileId, requestId, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/getInitialFetchStatus?profileId=" +
					profileId +
					"&requestId=" +
					requestId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	saveAuthorizedLocations: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/saveAuthorizedLocations", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getFetchSummary: function (profileId, userId, requestId, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/getFetchSummary?profileId=" +
					profileId +
					"&userId=" +
					userId +
					"&requestId=" +
					requestId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	activateAccounts: function (profileId, userId, requestId, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/activateAccounts?profileId=" +
					profileId +
					"&userId=" +
					userId +
					"&requestId=" +
					requestId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateIntegration: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateGoogleAccountIntegration", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	activateSupressLocation: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/suppressLocations", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateLocation: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/editAuthorizedLocations", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getGoogleLocationsForAccount: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getGoogleLocationsForAccount", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	uploadBulkFiles: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/uploadBulkFiles", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getFileUploadHistory: function (data, callback) {
		axios
			.post(config.server + "/getFileUploadHistory", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getFilterTagCsv: function (profileId, domainName, callback) {
		axios
			.get(
				config.server +
					"/getFilterTagCsv?profileId=" +
					profileId +
					"&domainName=" +
					domainName,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getGmbDataConnection: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getGmbDataConnection", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getSourceLocations: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getSourceLocations", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	saveGmbDataConnection: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/saveGmbDataConnection", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	createFacebookPost: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/createFacebookPost", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getFacebookPosts: function (profileId, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.get(config.server + "/getFacebookPosts?profileId=" + profileId, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getSyncHistory: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getSyncHistory", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getPubSubStatus: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getPubSubStatus", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	createWidget: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/createWidget", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getWidgets: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getWidgets", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteWidget: function (profileId, widgetId, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/deleteWidget?profileId=" +
					profileId +
					"&widgetId=" +
					widgetId,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateWidget: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateWidget", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getBulkAttributes: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/validateBulkEditRequest", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	bulkEditLocations: function (data, callback) {
		axios
			.post(config.server + "/bulkEditLocations", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getBulkEditHistory: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getBulkEditHistory", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	acceptInvite: function (profileId, userId, urlName, callback) {
		axios
			.get(
				config.server +
					"/acceptInvite?profileId=" +
					profileId +
					"&userId=" +
					userId +
					"&domainName=" +
					urlName,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateProfileVariables: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateProfileVariables", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateLocationDate: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateLocationDateStatus", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateUserDetails: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateUserDetails", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	downloadPostHistory: function (data, callback) {
		axios
			.post(config.server + "/downloadPostHistory", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				let response = resp.data;
				response.status = resp.status;
				callback(null, response);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	downloadSingleEntityTag: function (data, callback) {
		axios
			.post(config.server + "/getLocationsByGroupCsv", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				let response = resp.data;
				response.status = resp.status;
				callback(null, response);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	downloadBlockedDoamin: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getBlockedDomainsCsv", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	// deleteBlockedPublicEmailDomain: function (data, callback) {
	//     var TOKEN = cookie.readCookie("lctoken");
	//     axios.delete(config.server + "/deleteBlockedPublicEmailDomain", data,
	//         {
	//             headers: {
	//                 "Token": TOKEN,
	//                 "Content-Type": "application/json"
	//             }
	//         })
	//         .then((resp) => {
	//             callback(null, resp.data)
	//         })
	//         .catch((err) => {
	//             callback(err.response, null)
	//         })
	// },
	// getBlockedSpamDomain: function (data, callback) {
	//     var TOKEN = cookie.readCookie("lctoken");
	//     axios.post(config.server + "/getBlockedSpamDomain", data,
	//         {
	//             headers: {
	//                 "Token": TOKEN,
	//                 "Content-Type": "application/json"
	//             }
	//         })
	//         .then((resp) => {
	//             callback(null, resp.data)
	//         })
	//         .catch((err) => {
	//             callback(err.response, null)
	//         })
	// },
	// deleteBlockedSpamDomain: function (data, callback) {
	//     var TOKEN = cookie.readCookie("lctoken");
	//     axios.delete(config.server + "/deleteBlockedSpamDomain", data,
	//         {
	//             headers: {
	//                 "Token": TOKEN,
	//                 "Content-Type": "application/json"
	//             }
	//         })
	//         .then((resp) => {
	//             callback(null, resp.data)
	//         })
	//         .catch((err) => {
	//             callback(err.response, null)
	//         })
	// },
	bulkDeletePosts: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/bulkDeletePosts", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteBulkEdit: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/deleteBulkEditRequest", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getNotificationEditedFields: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getNotificationEditedFields", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getResponseTimeHistogram: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getResponseTimeHistogram/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getCommentHistogram: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getCommentHistogram/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getWordHistogram: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getWordHistogram/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	addReviewAssignee: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/addReviewAssignee/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateReviewAssignee: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateReviewAssignee/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteReviewAssignee: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/deleteReviewAssignee/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAssignedReviews: function (data, callback) {
		axios
			.post(config.server + "/getAssignedReviews/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllFilterTags: function (data, callback) {
		axios
			.post(config.server + "/getAllFilterTags/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateFilterTag: function (tagObject, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateFilterTag", tagObject, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocalpostSampleButtonLink: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocalpostSampleButtonLink", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getCleanupHistory: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getReviewCleanupHistory", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	updateBulkEditPost: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateBulkEditPost", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	updateScheduledLocalPost: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateScheduledLocalPost", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllImageLocation: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getAllLocationImages", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((response) => callback(null, response.data))
			.catch((error) => callback(error.response, null));
	},
	getLocationImageCount: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocationImageCount", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((response) => callback(null, response.data))
			.catch((error) => callback(error.response, null));
	},
	deleteImage: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/deleteImage", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((response) => callback(null, response.data))
			.catch((error) => callback(error.response, null));
	},
	flagImage: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/flagImage", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((response) => callback(null, response.data))
			.catch((error) => callback(error.response, null));
	},
	addMediaTag: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/addMediaTags", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((response) => callback(null, response.data))
			.catch((error) => callback(error.response, null));
	},
	createAiTone: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/createAIPersonalityTone", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((response) => callback(null, response.data))
			.catch((error) => callback(error.response, null));
	},
	removeMediaTag: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/removeMediaTags", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((response) => callback(null, response.data))
			.catch((error) => callback(error.response, null));
	},
	getLocationWiseImages: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocationWiseImages", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((response) => callback(null, response.data))
			.catch((error) => callback(error.response, null));
	},
	createAutomationRules: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/createAutomationRule", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	GetAutomationRule: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getAutomationRules", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
					// 'Content-Security-Policy': 'frame-ancestors none'
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateAutomationRule: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateAutomationRule", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
					// 'Content-Security-Policy': 'frame-ancestors none'
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteAutomationRule: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/deleteAutomationRule", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
					// 'Content-Security-Policy': 'frame-ancestors none'
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getAllMediaTags: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getAllMediaTags", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((response) => callback(null, response.data))
			.catch((error) => callback(error.response, null));
	},
	getLocationFriendlyName: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocations", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	createKeywordGroup: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/createKeywordGroup", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
					// 'Content-Security-Policy': 'frame-ancestors none'
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteKeywordGroup: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/deleteKeywordGroup", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
					// 'Content-Security-Policy': 'frame-ancestors none'
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getKeywordGroups: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getKeywordGroups", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateKeywordGroups: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateKeywordGroup", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
					// 'Content-Security-Policy': 'frame-ancestors none'
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	bulkUploadImages: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/bulkUploadImages", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getImageUploadHistory: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getImageUploadHistory", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteImageUploadHistory: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/deleteImageUploadHistory", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	createTeam: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/createTeam", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteTeam: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/deleteTeam", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getTeams: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getTeams", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateTeam: function (data, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateTeam", data, {
				headers: {
					Token: token,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateReviewShakeFetchStatus: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateReviewShakeFetchStatus/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationDataFetch: function (profileId, locationId, callback) {
		var token = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/getLocationFetchingStatus?profileId=" +
					profileId +
					"&locationId=" +
					locationId,
				{
					headers: {
						Token: token,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateTeamMemberAccess: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateTeamMemberAccess/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getUsersAndTeams: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getUsersAndTeams/", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getTeamReviews: function (data, callback) {
		axios
			.post(config.server + "/getTeamReviews/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getUserTeams: function (data, callback) {
		axios
			.post(config.server + "/getUserTeams/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateUserDomain: function (data, callback) {
		axios
			.post(config.server + "/updateUserDomain/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateProfileDomain: function (data, callback) {
		axios
			.post(config.server + "/updateProfileDomain/", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateIndexedLocations: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateIndexedLocations", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getUnmappedLocations: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getUnmappedLocations", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getMappedLocations: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getMappedLocations", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getPaginatedMappedLocations: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getPaginatedMappedLocations", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	dragAndDropLocations: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/dragAndDropLocations", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	createMasterLocation: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/createMasterLocation", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	deleteMasterLocation: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/deleteMasterLocation", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	hideMasterLocation: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/manageMasterVisibility", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editMasterLocation: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/editMasterLocation", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	saveLocationMapping: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/saveLocationMapping", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getLocationMappingStatus: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getLocationMappingStatus", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	cancelSaveMapping: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/cancelSaveMapping", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getUnmappedLocationsSources: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/getUnmappedLocationsSources", data, {
				headers: {
					Token: TOKEN,
					"Content-Type": "application/json",
				},
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateManagePermission: function (data, callback) {
		axios
			.post(config.server + "/updateManagePermission", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	update2FAPermission: function (data, callback) {
		axios
			.post(config.server + "/updateUserInfo", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	reportManagementDownload: function (profileId, domainName, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.get(
				config.server +
					"/reportManagementDownload" +
					"?profileId=" +
					profileId +
					"&domainName=" +
					domainName,
				{
					headers: {
						Token: TOKEN,
						"Content-Type": "application/json",
					},
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	googlePlayClientCredentials: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");

		axios
			.post(config.server + "/googlePlayClientCredentials", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	appStoreClientCredentials: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");

		axios
			.post(config.server + "/appStoreClientDetails", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	getAllPersonalityTones: function (data, callback) {
		axios
			.post(config.server + "/getAllPersonalityTones", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},

	deleteAiTone: function (data, callback) {
		axios
			.post(config.server + "/deleteAIPersonalityTone", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editAiTone: function (data, callback) {
		axios
			.post(config.server + "/editAIPersonalityTone", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	downloadAiTone: function (data, callback) {
		axios
			.post(config.server + "/openAiSettingsDownload", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	GetReviewDetails: function (data, callback) {
		axios
			.post(config.server + "/getReviewDetails", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	updateUserName: function (data, callback) {
		var TOKEN = cookie.readCookie("lctoken");
		axios
			.post(config.server + "/updateUsername", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	searchKeywordDownload: function (data, callback) {
		axios
			.post(config.server + "/searchKeywords", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getWalmartAuditHistory: function (data, callback) {
		axios
			.post(config.server + "/getWalmartAuditHistory", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getWalmartAuditSummary: function (data, callback) {
		axios
			.post(config.server + "/getWalmartAuditSummary", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	downloadWalmartAuditHistory: function (data, callback) {
		axios
			.post(config.server + "/downloadWalmartAuditHistory", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	bulkEditWalmartLocations: function (data, callback) {
		axios
			.post(config.server + "/bulkEditWalmartLocations", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getWalmartEditStatus: function (data,callback) {
		axios
			.post(
				config.server + "/getWalmartEditStatus",
				data,
				{
					headers: generateHeaderWithToken(),
				},
			)
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	editWalmartLocation: function (data, callback) {
		axios
			.post(config.server + "/editWalmartLocation", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	getSourceLocation: function (data, callback) {
		axios
			.post(config.server + "/getSourceLocations", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
	viewLocationFilterTags: function (data, callback) {
		axios
			.post(config.server + "/viewLocationFilterTags", data, {
				headers: generateHeaderWithToken(),
			})
			.then((resp) => {
				callback(null, resp.data);
			})
			.catch((err) => {
				callback(err.response, null);
			});
	},
};

export default new APIClient();

const generateHeaderWithToken = () => {
	let token = cookie.readCookie("lctoken");
	let adminToken = cookie.readCookie("superuser");
	let headersWithToken = {
		Token: token,
		"Content-Type": "application/json",
	};
	if (adminToken) {
		headersWithToken["AdminToken"] = adminToken;
	}
	return headersWithToken;
};
